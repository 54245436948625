@mixin impact-stat-icon (
  $stat-number,
  $image,
  $width,
  $height
) {
  .homefeaturecategory-homeboximpactstats tr:nth-child(#{$stat-number}) {
    &:before {
      content: '';
      width: $width;
      height: $height;
      display: block;
      background-image: url($assets-path + $image);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}

@if $impact-stats-enabled == true {
  .homefeaturecategory-homeboximpactstats {
    position: relative;
    color: $impact-stats-colour;
    padding: $impact-stats-padding-y 0;
    margin-top: $impact-stats-margin-top;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: $impact-stats-margin-bottom;
    border-radius: $impact-stats-border-radius;
    width: 100%;
    max-width: $impact-stats-max-width;

    // Solid colour
    @if $impact-stats-background-gradient == none {
      background-color: $impact-stats-background-colour;
    }

    // Gradient
    @else {
      background: $impact-stats-background-gradient;
    }

    // If we're at full width, lose the border radius
    @if $impact-stats-max-width == 100% {
      border-radius: 0;
    }

    // Background image
    @if $impact-stats-background-image != null {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url($assets-path + $impact-stats-background-image);
        z-index: 0;
        background-repeat: $impact-stats-background-image-repeat;
        background-size: $impact-stats-background-image-size;
        background-position: center;
        opacity: $impact-stats-background-image-opacity;
      }
    }

    .homeImpactWrapper {
      position: relative;
      width: 100%;
      max-width: $impact-stats-content-max-width;
      padding: 0 $site-bleed;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-direction: $impact-stats-overall-container-direction;
      background-color: none;

      @if $impact-stats-heading-enabled == true {
        > h2 {
          display: block;
          position: relative;
          text-align: $impact-stats-heading-text-align;
          font-size: $impact-stats-heading-font-size;
          color: $impact-stats-heading-colour;
          font-weight: $headings-font-weight;
          margin-bottom: $impact-stats-heading-margin-bottom;

          a {
            color: inherit;
          }
        }
      } @else {
        > h2 {
          display: none;
        }
      }
    }

    table {
      position: relative;
    }

    tbody {
      position: relative;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: $impact-stats-direction;
      align-items: flex-start; // https://impactful.slack.com/archives/GED9J1FKK/p1580144828002100
      text-align: $impact-stats-text-align;
    }

    th, td {
      border-top: 0;
    }

    tr {
      position: relative;
      text-align: inherit;
      display: flex;
      justify-content: center;
      flex-direction: $impact-stat-direction;
      align-items: center;
      padding: $impact-stat-padding;
      border: $impact-stat-border;
      border-width: $impact-stat-border-width;
      margin: $impact-stat-margin / 2;

      @media (min-width: $impact-stats-breakpoint + 1px) {
        flex: 1;
        min-width: $impact-stat-min-width;
      }

      @media (max-width: $impact-stats-breakpoint) {
        text-align: $impact-stats-mobile-text-align;
      }
    }

    td {
      display: block;
      padding: 0;
      position: relative;
    }

    // Figure
    td:first-child {
      > * {
        display: block;
        font-size: $impact-stats-figure-font-size;
        font-family: $impact-stats-figure-font-family;
        color: $impact-stats-figure-colour;
        margin: 0;
        line-height: $headings-line-height;
        font-weight: $headings-font-weight;
      }
    }

    td:last-child {
      color: $impact-stats-summary-colour;
      font-size: $impact-stats-summary-font-size;
      font-weight: $impact-stats-summary-font-weight;
      margin-top: $impact-stats-summary-margin-top;
    }

    table,
    td, tr {
      width: 100% !important;
      height: auto !important;
    }
  }


  //
  // Responsive
  //

  @include max-width(lg) {
    .homefeaturecategory-homeboximpactstats {
      padding: ($impact-stats-padding-y * .5) 0;
      margin-top: $impact-stats-margin-top * 5;
      margin-bottom: $impact-stats-margin-bottom * .5;
    }
  }

}
