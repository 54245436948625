@if $tagline-enabled == false {
  .tagline {
    display: none;
  }
} @else {
  .tagline {
    flex-grow: 1;
    font-family: $tagline-font-family;
    font-weight: $tagline-font-weight;
    font-size: $tagline-font-size;
    color: $tagline-colour;
    margin-right: $spacer;
    display: block;

    a {
      color: inherit;
    }
  }

  @media (max-width: $tagline-breakpoint) {
    .tagline {
      display: none;
    }
  }

  // Tagline above cta
  @if $tagline-above-cta-breakpoint {
    @media (min-width: $tagline-breakpoint + 1px) and (max-width: $tagline-above-cta-breakpoint) {
      .pageHeader .headerContent {
        display: grid;
        grid-template-columns: auto 1fr auto;
        grid-template-rows: auto auto;
      }

      .mainLogo {
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        max-width: 100% !important;
      }

      .tagline {
        grid-column: 2 / 4;
        grid-row: 1 / 2;
        text-align: right;
        font-size: 1em;
        margin-right: 0;
      }

      .headerContent .header-search {
        grid-column: 2 / 3;
        grid-row: 2 / 3;
      }

      .headerContent .mainCallToAction {
        grid-column: 3 / 4;
        grid-row: 2 / 3;
      }
    }
  }
}
