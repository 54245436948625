// This relates to 'checking out' a basket, e.g. the shop user journey

.paymentPage {
  width: 100%;
  max-width: $post-content-max-width;
  padding: 0 $site-bleed;
  padding-top: $spacer * 3; // This was previously margin-top but was causing collapsible margin issues when sticky header. This emulates .headerText.
  margin-left: auto;
  margin-right: auto;

  .formWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: $gap-width;
    padding: $gap-width;
    border: $border-width solid $border-colour;
    border-radius: $border-radius;
  }
}

.basketContent {
  margin-top: 0;
  margin-bottom: $gap-width;

  td {
    vertical-align: middle;
  }

  // "Basket"
  caption {
    text-align: left;

    h2 {
      font-size: $font-size-h5;
      margin-bottom: $spacer;
    }
  }
}

.basketAdditional {
  margin: 0;
  display: flex;
  flex-direction: column;
}

.deliveryAmount {
  h3 {
    font-size: $font-size-h5;
    margin-bottom: $spacer;
  }
}

.formQuestion.promoCode {
  flex-direction: row;
  flex-wrap: wrap;

  label {
    width: 100%;
  }

  #promoCode {
    flex-grow: 1;
    margin-right: 10px;
    width: auto;
  }

  #applyPromo {
    width: auto;
    flex-grow: 0;
    flex-shrink: 0;
    margin: 0;
  }
}

.basket {
  .vat,
  .total {
    text-align: right;
    align-self: flex-end;
    width: 100%;
    max-width: 400px;
  }

  .vat {
    @extend h5;
    margin-top: $spacer * 2;
  }

  .total {
    @extend h3;
    margin: 0;
    padding: 15px 0;
    border-top: 1px solid $border-colour;
    border-bottom: 1px solid $border-colour;
  }
}

.formQuestion.useBillingAddress {
  flex-direction: row;
  align-items: center;

  input[type="checkbox"] {
    margin-right: .5rem;
  }
}

// Complete purchase button & t&c


//
// Responsive
//

@include max-width(sm) {
  .paymentPage .formWrapper {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    padding-left: 0;
    padding-right: 0;
    &:not(:last-of-type) {
      margin: 0;
    }
  }
}