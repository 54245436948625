#paymentMethods {
  display: flex;
  flex-wrap: wrap;
  margin: -8px; // Compensate for flex gap

  &.radioWrapper .radio-choice {
    display: flex;
    flex-direction: column;
    border: 1px solid $border-colour;
    border-radius: $border-radius;
    margin: 8px;
    flex: 1;
    flex-grow: 1;
    padding: 20px 15px;
    transition: all $btn-transition-duration $transition-curve;

    &.selected-payment-method {
      border-color: $donate-colour;
    }
  }

  label {
    padding: 0;
    margin: 10px 0 0;
    font-size: 1em;
    font-weight: $font-weight-base;
    flex-grow: 1;
  }

  input[type="radio"] {
    margin: 0 auto;
  }

  img {
    display: block;
    margin: 0 auto;
  }
}

#invalid-payment-method {
  width: 100%;
  padding: 0 8px;
  margin-bottom: .5em;
  text-align: left;
}

@include max-width(md) {
  #paymentMethods {
    flex-direction: column;
  }
}
