// Cover our costs
.coverOurCostsContainer {
  font-size: $font-size-large;
  background-color: $grey-lightest;
  padding: $gap-width;
  margin-bottom: $gap-width;
  border-radius: $border-radius;

  .formQuestion {
    flex-direction: row;
    align-items: center;
    padding: 0;
  }

  label {
    margin-bottom: 0;
    margin-left: .5rem;
  }
}

// The value in the label
.coverCostLabel {
  color: $donate-colour;
}