@if $profile-pics-enabled == false {
  .profilePic,
  .memberProfilePic {
    display: none;
  }
} @else {
  .profilePic,
  .memberProfilePic {
    border-radius: $border-radius;
  }
}
