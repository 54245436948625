// It's possible to insert a 'file link' in the editor, with a required form submission
// BEFORE the user is allowed to download the file. When the user clicks on the download
// link, we need to modal popup the form.

#overlayContainer {
  position: absolute;
  text-align: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 40px 20px;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
  overflow: hidden;

  iframe {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 30px;
    overflow: hidden;
    background-color: #fff;
  }
}

// body class that gets added within the iframe
.handlers-formspopup {
  .customForm {
    padding-top: 0;
    margin-bottom: 0;
  }

  .postContent {
    padding-left: 0;
    padding-right: 0;
  }
}