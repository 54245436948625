.image-align-right,
.image-align-left,
.mediaImage {
  max-width: 100%;
}

@include max-width(sm) {
  .image-align-right,
  .image-align-left,
  img.postImageFloatLeft,
  img.postImageFloatRight {
    display: block;
    float: none;
    margin: 15px auto;
  }
}

.postContent .mediaImage {
  height: auto !important;
}
