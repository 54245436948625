//
// Fetch icon font
//

@if $icon-font-family == 'FontAwesome' {
  @import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
}


//
// Headings
//

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $headings-colour;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  margin-bottom: $headings-margin-bottom;
  text-transform: $headings-text-transform;
  letter-spacing: $headings-letter-spacing;
}

h1 {
  font-size: $font-size-h1;
}
h2 {
  font-size: $font-size-h2;
}
h3 {
  font-size: $font-size-h3;
}
h4 {
  font-size: $font-size-h4;
}
h5 {
  font-size: $font-size-h5;
}
h6 {
  font-size: $font-size-h6;
}

.postContent {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: $headings-margin-top;
  }
}

//
// Horizontal rules
//

hr {
  margin-top: $spacer-y;
  margin-bottom: $spacer-y;
  border: 0;
  border-top: $hr-border-width solid $hr-border-colour;
}

//
// Links
//

@mixin text-link(
  $link-colour: $link-colour,
  $link-decoration: $link-decoration,
  $link-decoration-colour: $link-decoration-colour,
  $link-hover-decoration: $link-hover-decoration
) {
  color: $link-colour;
  text-decoration: $link-decoration;
  text-decoration-color: $link-decoration-colour;
  transition: color $transition-duration $transition-curve;

  &:hover {
    @if $link-hover-style == darken {
      color: darken($link-colour, 10%);
    } @else if $link-hover-style == lighten {
      color: lighten($link-colour, 10%);
    }
    text-decoration: $link-hover-decoration;
    cursor: pointer;
  }
}

a {
  @include text-link;
}

@if $warn {
  @if (colour-contrast-two-colours($link-colour, #fff) == false) {
    @warn '#{$platform-name}: Your chosen link colour does not pass contrast ratio checks on a white background';
  }
}

//
// Responsive
//

@include max-width(lg) {
  body {
    font-size: $font-size-base * 0.9;
  }
}

@include max-width(md) {
  body {
    font-size: $font-size-base * 0.8;
  }
}
