@if $shop-enabled == true {
  .departmentsContent {
    @include context-container (
      $department-list-padding,
      $department-list-border-radius,
      $department-list-background-colour
    );
    margin-bottom: 10px;

    @include min-width(md) {
      width: $department-list-width;
      margin-right: $spacer * 3;
      @if $header-sticky-enabled == false {
        position: sticky;
        top: $spacer;
      }
    }

    h2 {
      font-size: $font-size-h5;
      margin-bottom: $spacer;
    }
  }

  .departmentList {
    @include list-unstyled;

    li {
      padding: .75em 0;
      border-bottom: 1px solid $border-colour;

      &:first-child {
        border-top: 1px solid $border-colour;
      }
    }
  }
}
