@mixin min-width($breakpoint) {
  @media (min-width: #{map-get($breakpoints, $breakpoint) + 1px}) {
    @content;
  }
}

@mixin max-width($breakpoint) {
  @media (max-width: #{map-get($breakpoints, $breakpoint)}) {
    @content;
  }
}
