@mixin parentless-container-wrapper($item-width) {
  margin-left: auto;
  margin-right: auto;

  @if $item-width != 100% {
    max-width: calc(#{$item-width} - #{$site-bleed * 2});

    @media (max-width: $item-width) {
      max-width: calc(100% - #{$site-bleed * 2});
    }
  }
}

@mixin card-prefab-flex-column($number-of-columns) {
  flex-basis: calc((100% - #{$card-gap-width * $number-of-columns}) / #{$number-of-columns});
}

@mixin card-row($cols) {
  @if $card-gap-width != 0 {
    width: calc( (100% - #{$card-gap-width * $cols}) / #{$cols} - 1px );
  } @else {
    width: calc( 100% / #{$cols} - 1px);
  }
   // IE can't handle the maths here, so always rounds UP to int which breaks the grid. So -1px. MY BEAUTIFUL PERFECTION! :(
}