// Black on white

.altStyle1 {
  @include alt-style(
    $base-colour: $white,
    $off-base-colour-1: darken($white, 10%),
    $off-base-colour-2: darken($white, 8%),
    $top-colour: $black
  );

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="url"],
  select,
  textarea {
    background-color: $white !important;
    border: 2px solid $black !important;
  }
}
