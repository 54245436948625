@if $nav-type == normal {
  .menuMain {
    width: 100%;
    background-color: $nav-background-colour;
    padding: 0;
    border-radius: $nav-normal-border-radius;

    li {
      position: relative;
    }

    // Hide this cloned in item
    .menuMainAlt {
      display: none;
    }
  }

  ul.topLevel {
    display: flex;
    margin: 0 auto;
  }

  @media (min-width: $nav-breakpoint + 1px) {
    .menuMain {
      max-width: $nav-normal-max-width;
      @include parentless-container-wrapper($nav-normal-max-width);
      margin-top: $nav-normal-margin-top;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: $nav-normal-margin-bottom;

      // If we have a transparent bar, then we want the item TEXT to align vertically
      // with the header content above it. This is assuming that the top level item's
      // lateral padding is 20px (* 2 = 40)
      @if $nav-background-colour == transparent {
        @include parentless-container-wrapper(
          #{$nav-normal-contents-max-width} + 40px
        );
      }
    }

    .menuMainAlt {
      display: none;
    }

    ul.topLevel {
      max-width: $nav-normal-contents-max-width;

      @if $nav-normal-border-radius != 0 and $nav-normal-align-items == left {
        > li:first-child > a {
          border-top-left-radius: $nav-normal-border-radius;
        }
      }

      @if $nav-normal-align-items == center {
        justify-content: center;
      }

      @if $nav-normal-align-items == right {
        justify-content: flex-end;

        @if $nav-normal-border-radius != 0 {
          > li:nth-last-child(3) > a {
            border-top-right-radius: $nav-normal-border-radius;
          }
        }
      }
    }

    .menuMain .topLevel li.level1 {
      > a {
        color: $nav-top-level-item-colour;
        font-family: $nav-top-level-item-font-family;
        font-size: $nav-top-level-item-font-size;
        font-weight: $nav-top-level-item-font-weight;
        padding: $nav-top-level-item-padding;
        background-color: $nav-top-level-item-background-colour;
      }

      &:hover > a {
        color: $nav-top-level-item-hover-colour;
        background-color: $nav-top-level-item-hover-background-colour;
      }
    }

    .menuMain ul.subMenu {
      position: absolute;
      z-index: 2;

      li:hover > a {
        color: $nav-submenu-item-hover-colour;
        background-color: $nav-submenu-item-hover-background-colour;
      }
    }

    ul.subMenu.level2 {
      top: 100%;
      left: 0;
    }

    ul.subMenu:not(.level2) {
      top: 0;
      left: 100%;
    }
  }

  //
  // Responsive
  //

  @media (max-width: $nav-breakpoint) {
    .pageHeader {
      position: relative;
    }

    .menuMain {
      position: absolute;
      max-height: 0;
      transition: all $transition-duration $transition-curve;
      z-index: 2;
      overflow: hidden;
      background-color: $nav-normal-mobile-background-colour;

      &.active {
        // max-height is being calculated with JS to insure it stretches to the bottom of the viewport
        overflow-y: scroll;
      }
    }

    .menuMain ul.topLevel {
      flex-direction: column;
    }

    // Set primary link colour
    .menuMain .topLevel > li > a {
      color: $nav-normal-mobile-top-level-item-colour;
      &:hover {
        color: $nav-normal-mobile-top-level-item-colour;
      }
    }

    .menuMain .subMenu {
      max-height: 0;
      display: block;
      transition: all $transition-duration $transition-curve;
      overflow: hidden;
      background-color: $nav-normal-mobile-submenu-background-colour;

      a {
        color: $nav-normal-mobile-submenu-item-colour;

        &:hover {
          color: $nav-normal-mobile-submenu-item-colour;
        }
      }

      &.active {
        max-height: unset;
      }
    }
  }

  @media (max-width: $cta-btn-breakpoint) {
    @if $cta-btn-background-colour == $nav-normal-mobile-background-colour {
      .menuMain .cta-button:not([class*="donate"]) {
        background-color: darken($cta-btn-background-colour, 10%);
      }
    }

    @if $donate-colour == $nav-normal-mobile-background-colour {
      .menuMain .cta-button.donate {
        background-color: darken($donate-colour, 10%);
      }
    }
  }
}
