.videoPopup {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10; // On top
}

.embed-container.videoPopUpWrapper {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  transform: translateY(-50%);
  width: 100%;
  max-width: 800px;
  padding: 20px;
  z-index: 2;
}

.videoBackground {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#000, 0.8);
  z-index: 1;
}

.videoPopup .embed-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  z-index: 100;
  background: transparent;
}

// BEGIN STYLES FROM https://github.com/RaisingITDev/platform/pull/3540/files
.youtube-player {
  position: relative;
  padding-bottom: 56.23%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000;
  margin: 5px;
}

.youtube-player img {
  bottom: 0;
  display: block;
  left: 0;
  margin: auto;
  max-width: 100%;
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  height: auto;
  cursor: pointer;
  -webkit-transition: 0.4s all;
  -moz-transition: 0.4s all;
  transition: 0.4s all;
}

.youtube-player img:hover {
  -webkit-filter: brightness(75%);
}

.youtube-player .play {
  height: 100%;
  width: 100%;
  display: block;
}

.youtube-player .play:before {
  text-align: center;
  width: 100px;
  height: 100px;
  line-height: 100px;
  z-index: 1;
  font-family: $icon-font-family;
  font-size: 40px;
  content: '\f04b';
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  display: block;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  color: white;
  border-radius: 100px;
}

@media screen and (max-width: 480px) {
  .youtube-player .play:before {
    width: 60px;
    height: 60px;
    font-size: 20px;
    line-height: 60px;
  }
}
// END STYLES FROM https://github.com/RaisingITDev/platform/pull/3540/files

// Vimeo popup player
.videoPopup .embed-container iframe#iframeVimeo {
  position: relative;
  height: 100vh;
}

.videoPopupClose {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: $btn-background-colour;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 40px;
  z-index: 101;

  &:before {
    content: '\f00d';
    font-family: $icon-font-family;
    color: $btn-text-colour;
  }

  &:hover {
    text-decoration: none;
    background-color: $btn-text-colour;

    &:before {
      color: $btn-background-colour;
    }
  }
}
