.container404 {
  @include context-container;
  width: calc(100% - #{$site-bleed * 2});
  max-width: 700px;
  margin: 4rem auto;

  > h1 {
    font-size: $font-size-h3;
  }

  h1 + h1 {
    display: none; // Some random duplicate h1 here
  }

  > *:last-child {
    margin-bottom: 0;
  }
}