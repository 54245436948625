//
// Associated lists
//

.associated {
  margin-bottom: $spacer * 2;
}

.associated:not(.associatedBox) {
  // List heading
  > h2 {
    font-size: $associated-list-heading-font-size;
  }

  // Item
  li {
    padding: .75em 0;

    @if $associated-item-border == true {
      border-bottom: 1px solid $border-colour;

      &:first-child {
        border-top: 1px solid $border-colour;
      }
    }
  }

  // Item image
  @if $associated-item-image-enabled == false {
    .associated-image-link {
      display: none;
    }
  } @else {
    .associated-image-link {
      display: block;
      width: 100%;
      margin-bottom: $spacer / 2;
      border-radius: $border-radius;
      overflow: hidden;

      .banner {
        display: block;
      }
    }
  }

  // Item heading
  li h3 {
    font-size: $associated-item-heading-font-size;
    text-transform: $associated-item-headings-text-transform;
    a { color: $associated-item-heading-colour; }
    @if $associated-item-summary-enabled == false {
      margin: 0;
    }
  }

  // Item summary
  li p {
    @if $associated-item-summary-enabled == false {
      display: none;
    } @else {
      font-size: $font-size-small;
      margin-bottom: 0;
    }
  }

  .featureFooter {
    > p {
      display: none; // Hide published, author, location etc.
    }

    > div:last-child {
      display: none; // Weird 'related topics' div here
    }
  }
}

.associatedListing {
  @include list-unstyled;

  li {
    display: none; // Revealed on nth-child below

    &:nth-child(-n+#{$associated-max-number-of-posts}) {
      display: block;
    }
  }
}

// Turn off 'latest'
@if $associated-latest-enabled == false {
  .associatedLatestPosts {
    display: none;
  }
}

// Turn off 'related'
@if $associated-related-enabled == false {
  .associatedRelatedPosts {
    display: none;
  }
}

// Turn off 'most read'
@if $associated-most-read-enabled == false {
  .associatedMostRead {
    display: none;
  }
}

// Turn off 'tag cloud'
@if $associated-tag-cloud-enabled == false {
  .associatedTagCloud {
    display: none;
  }
}
