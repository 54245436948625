.socialIcons,
.shareList {
  @include list-unstyled;
  display: flex;
  align-items: center;

  li {
    margin-right: $social-icons-gap;

    &:last-child {
      margin-right: 0;
    }
  }

  a {
    font-size: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: $social-icons-width;
    height: $social-icons-height;
    border-radius: $social-icons-border-radius;
    transition: background-color $btn-transition-duration $transition-curve;
    box-shadow: $social-icons-box-shadow;
    // background-color: $btn-background-colour; // Should we set a default here?

    @if $social-icons-background-colour != "brand" {
      background-color: $social-icons-background-colour;
    } @else {
      &.shareEmail {
        background-color: $btn-background-colour;
      }
    }

    &:hover {
      text-decoration: none;
      @if $social-icons-background-colour != "brand" {
        @include button-hover-state($social-icons-background-colour);
      }

      @if $social-icons-hover-colour != "brand" {
        &:before {
          color: $social-icons-hover-colour;
        }
      }
    }

    &:before {
      display: block;
      font-size: $social-icons-font-size;
      font-family: $icon-font-family;
      font-weight: normal;
      transition: color $transition-curve $btn-transition-duration;

      @if $social-icons-colour != "brand" {
        color: $social-icons-colour;
      }
    }
  }

  // Icon assignment
  .twitter:before {
    content: "\f099";
  }

  .facebook:before {
    content: "\f09a";
  }

  .youtube:before {
    content: "\f16a";
  } 

  .instagram:before {
    content: "\f16d";
  }

  .linkedin:before {
    content: "\f0e1";
  }

  .pinterest:before {
    content: "\f231";
  }

  .tripadvisor:before {
    content: "\f262";
  }

  .vimeo:before {
    content: "\f27d";
  } 

  .shareEmail:before {
    content: "\f003";
  }

  .search:before {
    content: "\f002";
  }

  [class*='mail']:before {
    content: "\f0e0";
  }
  
  [class*='phone']:before {
    content: "\f095";
  }

  .twitter {
    @include social-icons-brand-colours(
      $brand-colour: $twitter-brand-colour,
      $background-colour: $social-icons-background-colour,
      $icon-colour: $social-icons-colour,
      $hover-icon-colour: $social-icons-hover-colour
    );
  }

  .facebook {
    @include social-icons-brand-colours(
      $brand-colour: $facebook-brand-colour,
      $background-colour: $social-icons-background-colour,
      $icon-colour: $social-icons-colour,
      $hover-icon-colour: $social-icons-hover-colour
    );
  }

  .youtube {
    @include social-icons-brand-colours(
      $brand-colour: $youtube-brand-colour,
      $background-colour: $social-icons-background-colour,
      $icon-colour: $social-icons-colour,
      $hover-icon-colour: $social-icons-hover-colour
    );
  }

  .instagram {
    @include social-icons-brand-colours(
      $brand-colour: $instagram-brand-colour,
      $background-colour: $social-icons-background-colour,
      $icon-colour: $social-icons-colour,
      $hover-icon-colour: $social-icons-hover-colour
    );
  }

  .linkedin {
    @include social-icons-brand-colours(
      $brand-colour: $linkedin-brand-colour,
      $background-colour: $social-icons-background-colour,
      $icon-colour: $social-icons-colour,
      $hover-icon-colour: $social-icons-hover-colour
    );
  }

  .pinterest {
    @include social-icons-brand-colours(
      $brand-colour: $pinterest-brand-colour,
      $background-colour: $social-icons-background-colour,
      $icon-colour: $social-icons-colour,
      $hover-icon-colour: $social-icons-hover-colour
    );
  }

  .tripadvisor {
    @include social-icons-brand-colours(
      $brand-colour: $tripadvisor-brand-colour,
      $background-colour: $social-icons-background-colour,
      $icon-colour: $social-icons-colour,
      $hover-icon-colour: $social-icons-hover-colour
    );
  }
  
  .vimeo {
    @include social-icons-brand-colours(
      $brand-colour: $vimeo-brand-colour,
      $background-colour: $social-icons-background-colour,
      $icon-colour: $social-icons-colour,
      $hover-icon-colour: $social-icons-hover-colour
    );
  }
}

// If we are using transparent social icons,
// and our post share items are set to be left aligned
// then make the actual icon justify left so it vertically aligns with the post content
// See: https://trello.com/c/gj4OH70h/135
@if $social-icons-background-colour == transparent {
  .shareList a {
    @if $share-text-align == left {
      justify-content: flex-start;
    } @else if $share-text-align == right {
      justify-content: flex-end;
    }
  }
}

// Header social icons
.mainCallToAction .socialIcons {
  margin-right: $header-social-icons-margin-right;
  margin-bottom: $header-social-icons-margin-bottom;
  justify-content: flex-end;

  a {
    @if $social-icons-header-background-colour != "brand" {
      background-color: $social-icons-header-background-colour;
    }

    &:hover {
      @if $social-icons-header-background-colour != "brand" {
        @include button-hover-state($social-icons-header-background-colour);
      }

      @if $social-icons-header-hover-colour != "brand" {
        &:before {
          color: $social-icons-header-hover-colour;
        }
      }
    }

    &:before {
      @if $social-icons-header-colour != "brand" {
        color: $social-icons-header-colour;
      }
    }
  }

  .twitter {
    @include social-icons-brand-colours(
      $brand-colour: $twitter-brand-colour,
      $background-colour: $social-icons-header-background-colour,
      $icon-colour: $social-icons-header-colour,
      $hover-icon-colour: $social-icons-header-hover-colour
    );
  }

  .facebook {
    @include social-icons-brand-colours(
      $brand-colour: $facebook-brand-colour,
      $background-colour: $social-icons-header-background-colour,
      $icon-colour: $social-icons-header-colour,
      $hover-icon-colour: $social-icons-header-hover-colour
    );
  }

  .youtube {
    @include social-icons-brand-colours(
      $brand-colour: $youtube-brand-colour,
      $background-colour: $social-icons-header-background-colour,
      $icon-colour: $social-icons-header-colour,
      $hover-icon-colour: $social-icons-header-hover-colour
    );
  }

  .instagram {
    @include social-icons-brand-colours(
      $brand-colour: $instagram-brand-colour,
      $background-colour: $social-icons-header-background-colour,
      $icon-colour: $social-icons-header-colour,
      $hover-icon-colour: $social-icons-header-hover-colour
    );
  }

  .linkedin {
    @include social-icons-brand-colours(
      $brand-colour: $linkedin-brand-colour,
      $background-colour: $social-icons-header-background-colour,
      $icon-colour: $social-icons-header-colour,
      $hover-icon-colour: $social-icons-header-hover-colour
    );
  }

  .pinterest {
    @include social-icons-brand-colours(
      $brand-colour: $pinterest-brand-colour,
      $background-colour: $social-icons-header-background-colour,
      $icon-colour: $social-icons-header-colour,
      $hover-icon-colour: $social-icons-header-hover-colour
    );
  }

  .tripadvisor {
    @include social-icons-brand-colours(
      $brand-colour: $tripadvisor-brand-colour,
      $background-colour: $social-icons-header-background-colour,
      $icon-colour: $social-icons-header-colour,
      $hover-icon-colour: $social-icons-header-hover-colour
    );
  }
}

// Footer social icons
.pageFooter .socialIcons {
  a {
    @if $social-icons-footer-background-colour != "brand" {
      background-color: $social-icons-footer-background-colour;
    }

    &:hover {
      @if $social-icons-footer-background-colour != "brand" {
        @include button-hover-state($social-icons-footer-background-colour);
      }

      @if $social-icons-footer-hover-colour != "brand" {
        &:before {
          color: $social-icons-footer-hover-colour;
        }
      }
    }

    &:before {
      @if $social-icons-header-colour != "brand" {
        color: $social-icons-footer-colour;
      }
    }
  }

  .twitter {
    @include social-icons-brand-colours(
      $brand-colour: $twitter-brand-colour,
      $background-colour: $social-icons-footer-background-colour,
      $icon-colour: $social-icons-footer-colour,
      $hover-icon-colour: $social-icons-footer-hover-colour
    );
  }

  .facebook {
    @include social-icons-brand-colours(
      $brand-colour: $facebook-brand-colour,
      $background-colour: $social-icons-footer-background-colour,
      $icon-colour: $social-icons-footer-colour,
      $hover-icon-colour: $social-icons-footer-hover-colour
    );
  }

  .youtube {
    @include social-icons-brand-colours(
      $brand-colour: $youtube-brand-colour,
      $background-colour: $social-icons-footer-background-colour,
      $icon-colour: $social-icons-footer-colour,
      $hover-icon-colour: $social-icons-footer-hover-colour
    );
  }

  .instagram {
    @include social-icons-brand-colours(
      $brand-colour: $instagram-brand-colour,
      $background-colour: $social-icons-footer-background-colour,
      $icon-colour: $social-icons-footer-colour,
      $hover-icon-colour: $social-icons-footer-hover-colour
    );
  }

  .linkedin {
    @include social-icons-brand-colours(
      $brand-colour: $linkedin-brand-colour,
      $background-colour: $social-icons-footer-background-colour,
      $icon-colour: $social-icons-footer-colour,
      $hover-icon-colour: $social-icons-footer-hover-colour
    );
  }

  .pinterest {
    @include social-icons-brand-colours(
      $brand-colour: $pinterest-brand-colour,
      $background-colour: $social-icons-footer-background-colour,
      $icon-colour: $social-icons-footer-colour,
      $hover-icon-colour: $social-icons-footer-hover-colour
    );
  }

  .tripadvisor {
    @include social-icons-brand-colours(
      $brand-colour: $tripadvisor-brand-colour,
      $background-colour: $social-icons-footer-background-colour,
      $icon-colour: $social-icons-footer-colour,
      $hover-icon-colour: $social-icons-footer-hover-colour
    );
  }
}

//
// Responsive
//

@media (max-width: $header-social-icons-breakpoint) {
  .headerContent .socialIcons {
    display: none;
  }

  .menuMain .mainCallToAction .socialIcons {
    display: flex;
    padding: $nav-submenu-item-padding;
    margin-right: 0;
    margin-bottom: 10px;
    justify-content: flex-start;

    @if ($nav-type == normal) and
      ($social-icons-header-background-colour == transparent)
    {
      a:before {
        color: text-contrast($nav-normal-mobile-background-colour);
      }
    }

    @if ($nav-search-enabled == true) {
      .search {
        display: none;
      }
    }
  }
}
