// Style some jQuery ui elements that we use on the frontend
.ui-datepicker {
  background-color: #fff;
  font-size: $font-size-small;
  padding: 0.5em;
  border: 1px solid $border-colour;

  td {
    text-align: center;
  }
}

.ui-datepicker-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .ui-corner-all {
    width: 50%;
  }
}

.ui-datepicker-next {
  text-align: right;
}

.ui-datepicker-title {
  width: 100%;
  font-weight: $font-weight-bold;
  text-align: center;
}
