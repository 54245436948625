// These are the prefabs for the overall FEEDS layout
@mixin home-feeds-prefab (
  $number-of-feeds,
  $prefab
) {

  // 2 FEEDS LAYOUTS
  @if $number-of-feeds == 2 {

    // ROWs LAYOUT
    @if $prefab == 1  {
      .homeFeed {
        width: 100%;
        margin: 0 auto;
        padding-top: $home-feeds-padding-top;
        padding-bottom: $home-feeds-padding-bottom;
      }

      .feedsTitle,
      .feedList {
        width: 100%;
        max-width: $home-feeds-max-width;
        margin-left: auto;
        margin-right: auto;
      }

      @include max-width(lg) {
        .homeFeed {
          padding-top: $home-feeds-padding-top * .5;
          padding-bottom: $home-feeds-padding-bottom * .5;
        }
      }
    }

    // COLUMNS LAYOUTS
    @if $prefab >= 2 {
      .homeFeedsWrapper {
        padding-top: $home-feeds-padding-top;
        padding-bottom: $home-feeds-padding-bottom;
      }

      .homeFeeds {
        display: flex;
        max-width: $home-feeds-max-width;
        margin-left: auto;
        margin-right: auto;
      }

      @include max-width(md) {
        .homeFeed {
          margin-bottom: $gap-width;
        }
      }

      @include min-width(md) {
        .homeFeed {
          margin-right: $spacer * 2; // Slightly bigger gap

          &:last-child {
            margin-right: 0;
          }
        }

        // 50:50
        @if $prefab == 2 {
          .homeFeed {
            flex: 1;
          }
        }

        // ~ 75:25
        @if $prefab == 3 {
          .homeFeedBox1 {
            flex: 2;
          }

          .homeFeedBox2 {
            flex: 1;
            flex-shrink: 0;
          }
        }

        @if $prefab == 4 {
          .homeFeedBox1 {
            flex: 1;
            flex-shrink: 0;
          }

          .homeFeedBox2 {
            flex: 2;
          }
        }
      }

      @include max-width(lg) {
        .homeFeedsWrapper {
          padding-top: $home-feeds-padding-top * .5;
          padding-bottom: $home-feeds-padding-bottom * .5;
        }
      }
    }

    @include prefab-warn($prefab, 2);

  }

  // 3 FEEDS LAYOUTS
  @if $number-of-feeds == 3 {

    // ROWS LAYOUT
    @if $prefab == 1  {
      .homeFeed {
        width: 100%;
        margin: 0 auto;
        padding-top: $home-feeds-padding-top;
        padding-bottom: $home-feeds-padding-bottom;
      }

      .feedsTitle,
      .feedList {
        width: 100%;
        max-width: $home-feeds-max-width;
        margin-left: auto;
        margin-right: auto;
      }

      @include max-width(lg) {
        .homeFeed {
          padding-top: $home-feeds-padding-top * .5;
          padding-bottom: $home-feeds-padding-bottom * .5;
        }
      }
    }

    // COLUMNS LAYOUTS
    @if $prefab == 2 {
      .homeFeedsWrapper {
        padding-top: $home-feeds-padding-top;
        padding-bottom: $home-feeds-padding-bottom;
      }

      .homeFeeds {
        display: flex;
        max-width: $home-feeds-max-width;
        margin-left: auto;
        margin-right: auto;
      }

      @include max-width(md) {
        .homeFeed {
          margin-bottom: $gap-width;
        }
      }

      @include min-width(lg) {
        .homeFeed {
          margin-right: $spacer * 1.2; // Slightly bigger gap
          flex: 1;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      @include min-width(md) {
        .homeFeeds {
          flex-wrap: wrap;
        }

        .homeFeed {
          &:first-child {
            margin-right: $spacer * 1.2;
          }

          &:nth-child(1),
          &:nth-child(2) {
            flex: 1;
          }

          &:nth-child(3) {
            width: 100%;
          }
        }
      }

      @include max-width(lg) {
        .homeFeedsWrapper {
          padding-top: $home-feeds-padding-top * .5;
          padding-bottom: $home-feeds-padding-bottom * .5;
        }
      }
    }

    @include prefab-warn($prefab, 2);
  }

}
