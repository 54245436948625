// White on black - set from /accessibility

.altStyle2 {
  @include alt-style(
    $base-colour: $black,
    $off-base-colour-1: lighten($black, 20%),
    $off-base-colour-2: lighten($black, 10%),
    $top-colour: $white
  );

  @if $header-search-enabled == true {
    .header-search {
      #siteSearch {
        background-color: $white;
        &::placeholder {
          color: $black;
        }
      }
    }
  }
}
