.sandbox-payments-indicator {
  position: relative;
  overflow: hidden;
  width: 100%;
  @include parentless-container-wrapper($post-content-max-width);
  background-color: $warning-colour--background;
  padding: 25px 20px 15px;
  text-align: center;
  color: $warning-colour--on-background;
  border: 1px solid $warning-colour--on-background;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: $font-weight-bold;
  margin-bottom: $spacer * 2;
}

// Hashed bar at the top of the alert
.sandbox-payments-indicator div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  margin-bottom: 15px;
  display: block;
  width: 100%;
  background-color: #eaa30b;
  background-image: -webkit-repeating-linear-gradient(
    135deg,
    transparent,
    transparent 18px,
    rgba(255, 255, 255, 0.25) 18px,
    rgba(255, 255, 255, 0.25) 36px
  );
  background-image: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 18px,
    rgba(255, 255, 255, 0.25) 18px,
    rgba(255, 255, 255, 0.25) 36px
  );
}
