// NB. There are also event and appeal sidebar styles (including totalisers) in partials elsewhere

@if $sidebar-enabled == false {
  .postAside:not(.appealWidgetsWrapper):not(.eventWidgetsWrapper):not(.officeFeedWrapper) {
    display: none;
  }
}

.post .contentBlock {
  // display: flex;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;

  .postAside > * {
    background-color: $sidebar-background-colour;
    padding: $sidebar-padding;
  }

  @if $sidebar-layout == adjacent {
    .postContent {
      // flex-grow: 1;
      grid-column: 1 / 2;
      grid-row: 1 / -1;
      -ms-grid-row-span: 2; // DES-4042
    }

    .postAside {
      width: calc(#{$sidebar-adjacent-width} + #{$sidebar-adjacent-gap});
      // flex-shrink: 0;
      padding-left: $sidebar-adjacent-gap;
      grid-column: 2 / 3;

      // DES-4042
      &.eventWidgetsWrapper {
        -ms-grid-row: 1;

        + .postFeedWrapper {
          -ms-grid-row: 2;
        }
      }
    }
  }

  @if $sidebar-layout == below or $sidebar-layout == above {
    display: flex;
    flex-direction: column;

    .postContent,
    .postAside {
      width: 100%;
    }
  }

  @if $sidebar-layout == below {
    .postAside {
      margin-top: $gap-width;
    }
  }

  @if $sidebar-layout == above {
    .postAside {
      order: -1; // Hoist it up
    }
  }
}

// Fix for display of multiple offices on /contact page
.officeFeedWrapper .hiddenLeft {
  display: none;
}

//
// Responsive
//

@if $sidebar-layout == adjacent {
  @include max-width(lg) {
    .post .contentBlock {
      .postAside {
        width: calc(#{$sidebar-adjacent-width * .75} + #{$sidebar-adjacent-gap * .75});
        padding-left: $sidebar-adjacent-gap * .75;
      }
    }
  }

  @include max-width(md) {
    .post .contentBlock {
      display: flex; // Send sidebar under
      @if $sidebar-mobile-layout == above {
        flex-direction: column-reverse;
      }
      @if $sidebar-mobile-layout == below {
        flex-direction: column;
      }

      .postAside {
        width: 100%;
        padding-left: 0;
      }
    }

    // If we're on an appeal, we always want the sidebar hoisted up ABOVE the content not below
    .appealPostPage {
      .post .contentBlock {
        flex-direction: column-reverse;
      }
    }
  }
}
