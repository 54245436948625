.carousel,
.listedItem:not(.listedFaq) {
  .embed-container {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
    width: 100%;
    height: auto;
  }
}

.carouselSlide.containsVideo {
  max-width: 890px;
  margin-left: auto;
  margin-right: auto;
}

.carouselSlide,
.listedPost {
  .youtube,
  .vimeo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}