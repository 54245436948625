.skipLink {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;

  &:focus {
    left: 0;
    padding: .25em;
    margin: 1em;
    width: auto;
    height: auto;
    z-index: 3; // Over the logo
  }
}
