.faqListingPage .contentBlock .listContent {
  flex-direction: column;
  margin-left: 0;
  margin-right: 0;
  .listedFaq {
    max-width: 100%;
  }
}

.faqListingPage .listing {
  .contentBlockWrapper,
  .headerText {
    max-width: $faq-content-max-width;
  }
}

.accordianHeader {
  @include context-container (
    $context-container-padding: $faq-item-header-padding,
    $context-container-border-radius: $faq-item-header-border-radius,
    $context-container-background-colour: $faq-item-header-background-colour
  );
  // Leave room for icon
  padding-right: calc(#{$faq-item-header-padding} + 50px);
  margin-bottom: $gap-width;
  color: text-contrast($faq-item-header-background-colour);
  position: relative;

  h2 {
    font-size: $faq-item-header-font-size;
    margin: 0;
  }

  h2 + p:not(:empty) {
    margin-top: $headings-margin-bottom;
  }

  .postSummary {
    margin: 0;
  }

  .buttonIcon {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: $context-container-padding;
    transition: all $transition-duration $transition-curve;
    background-color: $faq-item-btn-background-colour;

    &:before {
      font-size: 1.2rem;
      color: $faq-item-btn-chevron-colour;
      transition: color $btn-transition-duration $transition-curve;
    }
  }

  &:hover {
    cursor: pointer; // Clicking the header expands the whole thing
    @include button-hover-state($context-container-background-colour, 2%);

    .buttonIcon {
      background-color: $faq-item-hover-btn-background-colour;
      border-color: $faq-item-hover-btn-background-colour;

      &:before {
        color: $faq-item-hover-btn-chevron-colour;
      }
    }
  }
}

// Rotate the icon so its now pointing up
.accordianOpen .accordianHeader .buttonIcon {
  transform: translateY(-50%) rotate(180deg);
}

.contentBlockWrapper.accordianBody {
  max-height: 0; // Use this for transition slide down
  overflow: hidden;
  transition: max-height $transition-duration-slow $transition-curve;
  margin: 0;
  padding-left: $faq-item-header-padding;
  padding-right: $faq-item-header-padding;

  // Need to emulate the reduction in the context-container() padding here
  @include max-width(sm) {
    padding-left: $faq-item-header-padding / 2;
    padding-right: $faq-item-header-padding / 2;
  }

  &.hidden {
    display: block !important; // We're going to use our own max-height css transition to reveal here
  }

  .postFooterWrapper {
    display: none; // UI clutter
  }
}

.accordianOpen .accordianBody {
  max-height: 5000px; // Big number

  @include max-width(sm) {
    max-height: 10000px; // Even bigger for mobile
  }
}


//
// Responsive
//

@include max-width(md) {
  .accordianHeader {
    // Have to re-inforce the right padding here to clear the arrow button
    // This is resetting the default behaviour of context-container()
    padding-right: calc(1rem + 40px);

    .buttonIcon {
      width: 30px;
      height: 30px;
      right: 1rem;

      &:before {
        font-size: 1rem;
      }
    }
  }
}
