.alert {
  color: $danger-colour--on-white;
}

.alert.alert--required {
  color: #757575;
}

em.alert {
  font-style: normal; // Remove italic from mandatory asterisks
}

// Form validation errors
.validateError,
.validateRequired,
.errorMessage {
  color: $danger-colour--on-white;
  font-weight: $font-weight-normal;
}

// Feedback notification message
.feedback-notification {
  display: block;
  padding: 0.5em 0.75em;
  margin-bottom: 0.75em;
  font-size: 0.875em;
  border-radius: 0.2em;
  border: 1px solid $border-colour;
  background: $white;

  a,
  a:link,
  a:visited {
    color: inherit;
  }
}

.feedback-notification--danger {
  color: $danger-colour--on-background;
  background: $danger-colour--background;
  border-color: darken($danger-colour--background, 10%);
}

.feedback-notification--warning {
  color: $warning-colour--on-background;
  background: $warning-colour--background;
  border-color: darken($warning-colour--background, 10%);
}

.feedback-notification--success {
  color: $success-colour--on-background;
  background: $success-colour--background;
  border-color: darken($success-colour--background, 10%);
}
