.homeFeaturesWrapper {
  background-color: $home-features-background-colour;
}

.homeFeatures {
  width: 100%;
  max-width: $home-features-max-width;
  margin-left: auto;
  margin-right: auto;
  padding-top: $home-features-padding-top;
  padding-bottom: $home-features-padding-bottom;

  // If we are at container width, then add in the site bleed here,
  // compensating for any home card x margins
  @if $home-features-max-width != 100% {
    padding-left: calc(#{$site-bleed} - #{$card-gap-width / 2});
    padding-right: calc(#{$site-bleed} - #{$card-gap-width / 2});
  }
}

// @include max-width(lg) {
//   .homeFeatures {
//     padding-top: $home-features-padding-top * .5;
//     padding-bottom: $home-features-padding-bottom * .5;
//   }
// }
