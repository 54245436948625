@mixin ghost-button(
  $border-colour: $btn-border-colour,
  $text-colour: $btn-text-colour,
  $border-width: $btn-border-width
) {
  background-color: transparent;
  border: $border-width solid $border-colour;
  color: $text-colour;

  &:hover {
    background-color: $border-colour;
    border-color: $border-colour;
    color: text-contrast($border-colour);
  }
}

@mixin button(
  $btn-background-colour: $btn-background-colour,
  $btn-text-colour: $btn-text-colour,
  $btn-border-radius: $btn-border-radius,
  $btn-border-width: $btn-border-width,
  $btn-border-colour: $btn-border-colour,
  $btn-padding-y: $btn-padding-y,
  $btn-padding-x: $btn-padding-x,
  $btn-box-shadow: $btn-box-shadow,
  $btn-font-weight: $btn-font-weight,
  $btn-text-transform: $btn-text-transform,
  $btn-text-decoration: $btn-text-decoration,
  $btn-hover-style: $btn-hover-style,
  $btn-transition-duration: $btn-transition-duration,
  $btn-font-family: $btn-font-family,
  $btn-letter-spacing: $btn-letter-spacing
) {
  display: inline-flex;
  position: relative;
  align-items: center;
  background-color: $btn-background-colour;
  color: $btn-text-colour;
  border: $btn-border-width solid $btn-border-colour;
  outline: 0;
  border-radius: $btn-border-radius;
  padding: $btn-padding-y $btn-padding-x;
  box-shadow: $btn-box-shadow;
  font-weight: $btn-font-weight;
  font-family: $btn-font-family;
  font-size: $btn-font-size;
  text-transform: $btn-text-transform;
  text-decoration: $btn-text-decoration;
  line-height: $btn-line-height;
  letter-spacing: $btn-letter-spacing;
  margin-right: 5px;
  transition: all $btn-transition-duration $transition-curve;
  text-align: center;
  justify-content: center;

  a {
    color: inherit;
  }

  &:hover {
    text-decoration: none;
    color: $btn-text-colour;
    cursor: pointer;
    @include button-hover-state($btn-background-colour);
  }

  // Ghost button
  @if $btn-background-colour == transparent {
    @include ghost-button();
  }
}

.cta-button,
.button,
button,
.readMore,
.addToCalender {
  @include button;
}

// When a client adds a button into post content, let's add some bottom margin
// Quite often they put a row of buttons, which then when they break the line on mobile
// lack any vertical margin between them. This gets regularly raised in QA
// E.g.: https://www.bugherd.com/projects/158378/tasks/27
.postContent,
.listingIntro {
  .cta-button {
    margin-bottom: 5px;
  }
}

// Set donate button colour
.cta-button,
.button,
button {
  &.donate {
    // Solid buttons
    @if $donate-btn-background-colour != transparent {
      background-color: $donate-colour;
      color: $donate-btn-colour;
      border-color: $donate-colour;

      &:hover {
        @include button-hover-state($donate-colour);
      }
    }
    // Ghost buttons
    @else {
      @include ghost-button(
        $border-colour: $donate-colour,
        $text-colour: $donate-btn-colour
      );
    }
  }
}

// This mixin will REMOVE any button styles from an element
// Occassionally in the platform there are elements that are marked up as
// buttons that we don't actually want to style as buttons. Whilst this
// seems counter intuitive, it saves us from writing a lot of :not selectors
// in our button assignment above
@mixin button-reset {
  display: initial;
  background-color: initial;
  color: initial;
  border-radius: initial;
  padding: initial;
  box-shadow: initial;
  font-weight: initial;
  text-transform: initial;
  line-height: initial;
  margin-right: initial;
  transition: initial;

  &:hover {
    color: initial;
    cursor: initial;
    background-color: initial;
  }
}

// On the /my-details page we are asking users to click a button to email themselves a reset password link
// This needs a bit of tweaking otherwise it spans 100% width because of the flexbox behaviour of its parent
button#reset-password {
  align-self: flex-start;
}