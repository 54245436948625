@mixin card-basic (
  $card-basic-image-position: $card-basic-image-position,
  $prefab: 0
) {
  flex-direction: column;

  #{$card-details} {
    flex-grow: 1;
  }

  @if $card-basic-image-position == below {
    #{$card-details} {
      order: -1;
    }
  }

  //
  // Prefab styles
  //

  @if $prefab == 1 {
    // Default
  }

  @if $prefab == 2 {
    border: 0;

    #{$card-image-link} {
      margin-bottom: $spacer;
    }

    #{$card-details} {
      padding: 0 0 $spacer;
      background-color: transparent; // You can't really have a background colour with this one
    }

    &:hover {
      #{$card-details} {
        background-color: transparent;
      }

      #{$card-details} {
        > p {
          color: currentColor;
        }
      }
    }
  }

  @if $prefab == 3 {
    border: 0;

    #{$card-details},
    footer {
      max-width: calc(100% - #{$spacer * 2});
      margin: 0 auto;
    }

    #{$card-image-link} + div,
    #{$card-image-link} + div + footer {
      transform: translateY(#{$spacer * -1});
      transition: transform $transition-duration $transition-curve;
    }

    &:hover {
      #{$card-image-link} + div,
      #{$card-image-link} + div + footer {
        transform: translateY(#{$spacer * 2 * -1});
      }
    }
  }

  @if $prefab == 4 {
    border: 0;

    p {
      display: none;
    }

    h2 {
      margin: 0;
      text-align: center;
    }
  }

  @if $prefab == 5 {
    border: 0;

    #{$card-image-link} {
      width: calc(100% - #{$spacer});
    }

    #{$card-details} {
      width: calc(100% - #{$spacer});
      align-self: flex-end;
      transform: translateY(#{$spacer * -1});
    }
  }

  @if $prefab == 6 {
    border: 0;

    #{$card-image-link} {
      width: calc(100% - #{$spacer});
      align-self: flex-end;
    }

    #{$card-details} {
      width: calc(100% - #{$spacer});
      transform: translateY(#{$spacer * -1});
    }
  }

  @include prefab-warn($prefab, 6);
}
