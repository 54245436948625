.mainCallToAction {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;

  .cta-button:not([class*="donate"]) {
    @include button(
      $btn-background-colour: $cta-btn-background-colour,
      $btn-text-colour: $cta-btn-colour
    );
    margin-right: 0;
  }

  .cta-button {
    margin: 2px 0 2px $cta-btn-gap;
    // margin-left: $cta-btn-gap;

    &:first-child {
      margin-left: 0;
    }
  }

  @if $header-social-icons-above-cta-buttons == true {
    flex-direction: column;
    align-items: flex-end;
  }
}

.menuMain .mainCallToAction {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  > * {
    display: none;
  }
}

.headerContent .mainCallToActionButtons {
  text-align: right;
}

// Hide header donate button on appeal/fundraiser post / donate forms?
@if $cta-donate-on-appeal == false {
  .appealsPostBody,
  body.donate {
    .mainCallToAction .cta-button.donate {
      display: none;
    }
  }
}


//
// Responsive
//

@media (max-width: $cta-btn-breakpoint) {
  .headerContent .cta-button:not(.persist) {
    display: none;
  }

  .menuMain .mainCallToAction .mainCallToActionButtons {
    display: flex;
    flex-direction: column;
    padding: $nav-submenu-item-padding;
    padding-top: 0;

    .cta-button {
      margin: 4px 0;
    }

    .persist {
      display: none; // This one is still in the header
    }

    @if $cta-btn-background-colour == $nav-background-colour {
      .cta-button:not([class*="donate"]) {
        background-color: darken($cta-btn-background-colour, 10%);
      }
    }

    @if $donate-colour == $nav-background-colour {
      .cta-button.donate {
        background-color: darken($donate-colour, 10%);
      }
    }

    // Ghost
    @if $cta-btn-background-colour == transparent {
      .cta-button:not([class*="donate"]) {
        @include ghost-button(
          $border-colour: text-contrast($nav-normal-mobile-background-colour),
          $text-colour: text-contrast($nav-normal-mobile-background-colour)
        )
      }
    }
  }
}