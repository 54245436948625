// This is the mobile menu button

// If we've left a '0' string value here, we need to append a 'px'
// for the calc to actually work
@if $header-content-padding-bottom == 0 {
  $header-content-padding-bottom: $header-content-padding-bottom + 'px';
}

// HIDE it before it is popped into the .mainCallToAction with JS to minimise flash
.pageHeader > .menuMainAlt {
  display: none;
}

.menuMainAlt {
  @include button (
    $btn-background-colour: $burger-btn-background-colour,
    $btn-text-colour: $burger-btn-text-colour,
    $btn-text-transform: $burger-btn-text-transform
  );
  margin: 2px 0 2px $cta-btn-gap;
  position: relative;
  transition: background-color $btn-transition-duration $transition-curve; // Exclude any other transitions

  // Burger icon
  &:before {
    content: '\f0c9';
    font-family: $icon-font-family;
    font-weight: normal;
    margin-right: .5em;
    width: 12.5px;
  }

  &:hover {
    background-color: $btn-background-colour; // Disable this here, move it to active class
  }

  &.active {
    color: $btn-text-colour;
    @include button-hover-state($btn-background-colour);

    &:before {
      content: '\f00d';
    }
  }

  // Ghost button
  @if $burger-btn-background-colour == transparent {
    @include ghost-button(
      $border-colour: $brand-primary,
      $text-colour: $brand-primary
    )

    &.active {
      background-color: $brand-primary;
      border-color: $brand-primary;
      color: text-contrast($brand-primary);
    }
  }

  @if $burger-btn-icon-breakpoint != null {
    $btn-padding-y-string: quote($btn-padding-y);
    $btn-padding-y-em-index: str-index($btn-padding-y-string, 'em');
    $btn-padding-y-rem-index: str-index($btn-padding-y-string, 'rem');

    @media (max-width: $burger-btn-icon-breakpoint) {
      font-size: 0;

      // If we have an em value here, convert it to a rem value
      // I'm assuming we are under md breakpoint, at which point body gets a .8 multiplier on font size
      @if ($btn-padding-y-em-index != null) and ($btn-padding-y-rem-index == null) {
        padding: emToRem(#{$btn-padding-y * .9}) emToRem(#{$btn-padding-y * .9});
      }
      @else {
        padding: $btn-padding-y * .9;
      }

      &:before {
        font-size: .9rem;
        margin: 0;
      }
    }
  }
}

@if $burger-btn-bar-breakpoint != null {
  @media (max-width: $burger-btn-bar-breakpoint) {
    .menuMainAlt {
      font-size: .9rem;
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 0;
      right: 0;
      margin: 0;
      height: 40px;
      padding-top: 0;
      padding-bottom: 0;
      border-radius: 0;

      &:before {
        margin-right: .5em;
      }
    }

    .pageHeader .headerContent {
      padding-bottom: calc(40px + #{$header-content-padding-bottom});
    }

  }
}
