// Chevrons - Initiate an empty chevron element here, and opt in elsewhere
li.hasSubmenu > a:after {
  content: "";
  font-family: $icon-font-family;
  font-size: $font-size-small;
  flex-grow: 1; // Fill remaining space
  text-align: right; // Send the icon to the right of the whole after element
  transition: all $transition-duration $transition-curve;
}

// Nav normal
@if $nav-type == normal {
  @media (min-width: $nav-breakpoint + 1px) {
    @if $nav-top-level-chevrons-enabled == true {
      li.level1.hasSubmenu {
        > a:after {
          content: "\f078"; // down chevron
          color: $nav-top-level-chevrons-colour;
          margin-left: 10px;
        }

        &:hover > a:after {
          transform: rotate(180deg); // Gives us a nice transition
          color: $nav-top-level-item-hover-colour;
        }
      }
    }

    @if $nav-submenu-chevrons-enabled == true {
      ul.subMenu .hasSubmenu {
        > a {
          padding-right: 40px; // Clear the icon

          &:after {
            content: "\f054"; // right chevron
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 20px;
            color: $nav-submenu-chevrons-colour;
          }
        }

        &:hover > a:after {
          color: $nav-submenu-item-hover-colour;
        }

        // If menu opens left, chevron must point left.
        &.hasSubmenuLeft:hover > a:after {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }
  }

  @media (max-width: $nav-breakpoint) {
    @if $nav-normal-mobile-chevrons-enabled == true {
      li.hasSubmenu {
        > a:after {
          content: "\f078"; // down chevron
          color: $nav-normal-mobile-chevrons-colour;
        }

        > a.active:after {
          content: "\f077"; // up chevron
        }
      }
    }
  }
}

// Flyout
@if $nav-type == flyout {
  @if $nav-top-level-chevrons-enabled == true {
    li.level1.hasSubmenu {
      > a:after {
        content: "\f078"; // down chevron
        color: $nav-top-level-chevrons-colour;
        margin-left: 10px;
      }

      > a.active:after {
        content: "\f077"; // up chevron
        margin-left: 10px;
      }
    }
  } @else {
    @if $warn == true {
      @warn "Consider turning top level chevrons on for flyout menu!";
    }
  }

  @if $nav-submenu-chevrons-enabled == true {
    li:not(.level1).hasSubmenu {
      > a:after {
        content: "\f078"; // down chevron
        color: $nav-submenu-chevrons-colour;
        margin-left: 10px;
      }

      > a.active:after {
        content: "\f077"; // up chevron
        margin-left: 10px;
      }
    }
  } @else {
    @if $warn == true {
      @warn "Consider turning submenu chevrons on for flyout menu!";
    }
  }
}

// Nav parent clickable - mixin
@mixin nav-parent-clickable {
  @if $nav-parent-clickable-enabled == true {
    .menuMain li > span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 42.5px;
      flex-grow: 0;
      flex-shrink: 0;
      background-color: $nav-parent-clickable-chevron-background-colour;

      &:after {
        font-family: $icon-font-family;
        color: $nav-parent-clickable-chevron-colour;
        transition: all $transition-duration $transition-curve;
        font-size: 1.25rem;
        font-weight: $font-weight-bold;

        @if $nav-parent-clickable-chevron-icon-type == chevron {
          content: "\f107";
        }
        @if $nav-parent-clickable-chevron-icon-type == plus {
          content: "\f067";
        }
      }

      &.active {
        background-color: $nav-parent-clickable-chevron-active-background-colour;

        &:after {
          color: $nav-parent-clickable-chevron-active-colour;
          @if $nav-parent-clickable-chevron-icon-type == chevron {
            transform: rotate(180deg);
          }

          @if $nav-parent-clickable-chevron-icon-type == plus {
            content: "\f068";
          }
        }
      }
    }
  }
}

// Nav parent clickable - assignment
@if $nav-type == normal {
  @media (max-width: $nav-breakpoint) {
    @include nav-parent-clickable;
  }
}

@if $nav-type == flyout {
  @include nav-parent-clickable;
}
