a:not(.mainLogo),
button,
.readMore,
.quickGivingPanel .donationAmountFigure {
  &:focus-visible,
  &:focus-visible:before {
    background-color: $accessibility-highlight-colour !important;
    color: text-contrast($accessibility-highlight-colour) !important;
    outline: none;
  }

  &:focus-visible {
    box-shadow: $accessibility-highlight-box-shadow;
  }
}

// All
input,
select,
textarea {
  &:focus {
    outline: 2px solid $accessibility-highlight-colour;
  }
}
