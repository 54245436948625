// Things that are specific to the fundraiser appeal post

// Fundraiser's profile pic and bio
// .fundraiserContent .fundraiserProfilePic {
//   max-width: 200px;
//   float: left;
//   margin: 0 $spacer $spacer 0;
// }

// @include max-width(sm) {
//   .fundraiserContent .fundraiserProfilePic {
//     display: block;
//     float: none;
//     margin: 0 auto $spacer;
//   }
// }

.postContent.fundraiserContent {
  // border: 1px solid red;
  display: flex;
  flex-direction: column;
  // align-items: flex-start;

  .fundraiserProfilePic,
  .fundraiserName {
    order: -1;
  }

  .fundraiserProfilePic {
    margin: 0 auto 20px;
    max-width: 140px;
  }

  .fundraiserName {
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    font-size: $font-size-h3;
    display: block;
    text-align: center;
    width: 100%;
    margin-bottom: $gap-width;
    padding-bottom: .5rem;
    border-bottom: 1px solid $border-colour;
  }
}



.associatedBox.parentAppeal {
  // On a fundraiser appeal, there's a sidebar item for the parent appeal.
  // This gets a banner image of 300x90px, which looks terrible, always.
  // So let's always hide it.
  > a .banner {
    display: none;
  }

  // Add a little 'Fundraising for' text to give more context
  &:before {
    content: 'Fundraising for...';
    display: block;
    font-size: $font-size-small;
    color: $grey-light;
  }
}