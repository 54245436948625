// A table with the class of .shoppingList is used for event tickets/extras, as well as during the basket stage (with the addition of .basketContent)
// Here we're defining some specific rules for these tables, especially to ensure that they display well on mobile

.shoppingList {
  td {
    vertical-align: middle;
  }
}

// This needs a min width to stop the buttons wrapping under
.quantityColumn {
  min-width: 135px;
  text-align: center;
}

.removeColumn {
  text-align: center;
}

.priceColumn {
  text-align: right;
}

// Not clear to me what this actually does
.spacingColumn {
  display: none;
}

.enquiryColumn {
  text-align: center;
}

// Ticket extras as checkboxes
.ticketDetails .checkboxes .formQuestion {
  flex-direction: row;
  align-items: center;

  label {
    margin: 0 0 0 .5em;
  }
}

//
// Responsive
//

@include max-width(sm) {
  .shoppingList {
    thead {
      display: none; // I think the users can work this out?..
    }

    tr {
      display: grid;
      grid-template-rows: auto auto;
      border-top: 1px solid $border-colour;

      &:last-child {
        border-bottom: 1px solid $border-colour;
      }

      > * {
        align-self: center;
      }
    }

    td {
      border: 0;
    }

    .itemColumn,
    .priceColumn {
      padding-left: 0;
      padding-right: 0;
    }

    .itemColumn {
      grid-row: 1 / 2;
    }

    .priceColumn {
      grid-row: 2 / 3;
      text-align: left;
      padding-top: 0;
    }

    .quantityColumn {
      grid-row: 1 / 3;
      padding-right: 0;
      text-align: right;
    }
  }

  // Event ticket/extras
  .shoppingList:not(.basketContent) {
    tr {
      grid-template-columns: 1fr auto;
    }

    .itemColumn {
      grid-column: 1 / 2;
    }

    .priceColumn {
      grid-column: 1 / 2;
    }

    .quantityColumn {
      grid-column: 2 / 3;
    }
  }

  // Basket
  .basketContent {
    tr {
      grid-template-columns: auto 1fr auto;
    }

    .removeColumn {
      grid-column: 1 / 2;
      grid-row: 1 / 3;
      padding-left: 0;
    }

    .itemColumn {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .itemThumb {
        margin-bottom: .5rem;
      }
    }

    .itemColumn,
    .priceColumn {
      grid-column: 2 / 3;
    }

    .quantityColumn {
      grid-column: 3 / 4;
    }
  }
}