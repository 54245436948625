// There are various tables that get rendered on an event post for booking reasons
.eventContent section:not(.contentWrapper) {
  margin-top: $spacer * 2;
  margin-bottom: $spacer * 2;

  // "Book a place" et al. titles
  caption {
    text-align: left;

    h2 {
      font-size: $font-size-h5;
      margin-bottom: $spacer;
    }
  }
}

// "Book" button wrapper at the bottom
.eventBookingWrapper {
  @include context-container(
    $context-container-padding: $button-actions-padding,
    $context-container-background-colour: $button-actions-background-colour
  );
  font-size: $font-size-large;
}

.eventBooking {
  display: flex;

  @if ($button-actions-align-buttons == center) {
    justify-content: center;
  }

  @if ($button-actions-align-buttons == right) {
    justify-content: flex-end;
  }

  .button:last-child {
    margin-right: 0;
  }
}
