@if $totaliser-enabled == false {
  .totaliserPanel {
    display: none;
  }
} @else {
  .appealTotaliser {
    margin-bottom: $gap-width;
    padding: $gap-width;
    border: 1px solid $border-colour;
    border-radius: $border-radius;

    @if $totaliser-panel-background-colour != transparent {
      background-color: $totaliser-panel-background-colour;
    }
  }

  .raisedWrapper,
  .targetWrapper {
    display: flex;
    flex-direction: column;
  }

  .totaliserLabel {
    font-size: $font-size-small;
  }

  .totaliserFigure {
    font-weight: $font-weight-bold;
    line-height: 1.1;
  }

  .raisedWrapper .totaliserFigure {
    color: $donate-colour;
  }

  .totaliserBG {
    display: block;
    background-color: $grey-lightest;
    border: 1px solid $border-colour;
    border-radius: $border-radius;
    overflow: hidden;
  }

  .totaliserDone {
    background-color: $donate-colour;
    display: block;
  }

  // Vertical totaliser
  @if $totaliser-layout == vertical {
    .appealTotaliser .totaliserPanel {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    .targetWrapper {
      order: -1; // Jump this to the top
    }

    .targetWrapper,
    .raisedWrapper {
      align-items: center;
    }

    .raisedAmount {
      font-size: $font-size-h3;
    }

    .totaliserBG {
      width: $totaliser-width;
      height: 200px;
      position: relative;
      margin: 15px auto;
    }

    @keyframes totaliser-vertical-done-fill {
      0% {
        transform: translateY(100%);
      }

      100% {
        transform: translateY(0);
      }
    }

    .totaliserDone {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      animation: totaliser-vertical-done-fill 3s $transition-curve;
    }
  }

  // Horizontal totaliser
  @if $totaliser-layout == horizontal {
    // Helpfully, appeals and fundraiser posts have different markup here...
    .appealTotaliser .totaliserPanel,
    .fundraiserPostPage .appealTotaliser {
      position: relative;
      display: flex;
      flex-direction: row-reverse;
      flex-wrap: wrap;
    }

    .totaliserBG {
      transform: rotate(-90deg) translateX(-100%);
      transform-origin: top left;
      height: 200px; // Set a fall back height
      width: $totaliser-width;
      position: absolute;
    }

    @keyframes totaliser-horizontal-done-fill {
      0% {
        transform: translateY(-100%);
      }

      100% {
        transform: translateY(0);
      }
    }

    .postAside .totaliserDone {
      animation: totaliser-horizontal-done-fill 3s $transition-curve;
    }

    // Appeal posts
    .appealTotaliser .totaliserPanel {
      padding-top: $totaliser-width;
      .totaliserBG {
        top: 0;
        left: 0;
      }
    }

    // Fundraiser posts
    .fundraiserPostPage .post .contentBlock .postAside > .appealTotaliser {
      padding: ($totaliser-width + $gap-width) $gap-width $gap-width;
      .totaliserBG {
        top: $gap-width;
        left: $gap-width;
      }
    }

    .targetWrapper,
    .raisedWrapper {
      width: 50%;
      margin-top: 5px;
      margin-bottom: 0;
    }

    .targetWrapper {
      align-items: flex-end;
    }

    // Some tricky maths for sidebar horizontal appeal totalisers
    .postAside .totaliserBG {
      @if $sidebar-layout == adjacent {
        height: calc(#{$sidebar-adjacent-width} - #{$gap-width * 2});
      }

      @if $sidebar-layout == below or $sidebar-layout == above {
        height: calc(
          #{$post-content-max-width} - (#{$gap-width * 2}) - #{$site-bleed * 2}
        );
        @media (max-width: $post-content-max-width) {
          height: calc(100vw - (#{$gap-width * 2}) - #{$site-bleed * 2});
        }
      }
    }
  }

  // Hide a second totaliser that gets populated in an appeal post's footer
  .appealFooter .totaliserPanel {
    display: none;
  }

  //
  // Responsive
  //

  @if $totaliser-layout == horizontal {
    @if $sidebar-layout == adjacent {
      .postAside .totaliserBG {
        @include max-width(lg) {
          height: calc(#{$sidebar-adjacent-width * 0.75} - #{$gap-width * 2});
        }

        @include max-width(md) {
          height: calc(100vw - #{$site-bleed * 2} - #{$gap-width * 2});
        }
      }
    }
  }
}
