.carousel {
  width: 100%;
  max-width: $carousel-max-width;
  margin-top: $carousel-margin-top;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: $carousel-margin-bottom;
  position: relative;

  @if $carousel-max-width != 100% {
    padding-left: $site-bleed;
    padding-right: $site-bleed;
  }
}

.carouselSlides {
  @include list-unstyled;
  position: relative;
}

.carouselSlide {
  display: block;
  width: 100%;
  position: relative;
  z-index: 0;
  pointer-events: initial;
  transition: opacity $transition-duration-slow $transition-curve;
  border-radius: $carousel-border-radius;
  overflow: hidden; // This possible causes a bug? Previous comment: 'Hiding overflow disables any .highlighted carousel posts' - but I can't figure out what that means.

  img {
    display: block;
    width: 100%;
  }
}

.carouselSlide.hidden {
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: -1;
  display: block !important; // We actually don't want to hide this
  pointer-events: none;

  .carouselSlideDetail {
    pointer-events: none; // Disable all click events we might have
  }
}

.carouselSlideTitle {
  display: block;

  img {
    display: block;
    width: 100%;
  }

  @if $carousel-image-overlay != null {
    @media (min-width: $carousel-breakpoint + 1px) {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $carousel-image-overlay;
      }
    }
  }
}

.carouselDetailWrapper {
  display: flex;
  pointer-events: none; // Disable this here so we can click through
  width: 100%;
  max-width: $carousel-contents-max-width;
  margin: 0 auto;

  @if ($carousel-max-width == 100%) and ($carousel-contents-max-width != 100%) {
    @include parentless-container-wrapper($carousel-contents-max-width);
  }

  // Position-y
  @if $carousel-details-position-y == top {
    align-items: flex-start;
  }

  @if $carousel-details-position-y == center {
    align-items: center;
  }

  @if $carousel-details-position-y == bottom {
    align-items: flex-end;
  }

  // Position-x
  @if $carousel-details-position-x == left {
    justify-content: flex-start;
  }

  @if $carousel-details-position-x == center {
    justify-content: center;
  }

  @if $carousel-details-position-x == right {
    justify-content: flex-end;
  }
}

.carouselSlideDetail {
  background-color: $carousel-details-background-colour;
  padding: $carousel-details-padding;
  border-radius: $carousel-details-border-radius;
  width: 100%;
  margin: $carousel-details-margin-y $carousel-details-margin-x;
  box-shadow: $carousel-details-box-shadow;
  text-align: $carousel-details-text-align;
  pointer-events: all; // Reintroduce here

  > *:last-child {
    margin-bottom: 0;
  }

  &:hover {
    cursor: pointer; // Thing acts as a link
  }
}

// If we have multiple slides, and we have dots, and we have our carousel
// details style set to 'below', then we need to bump up the bottom padding
// on the details a bit to account for the dots
@if ($carousel-buttons-enabled == true) and
  ($carousel-buttons-type == dots) and
  ($carousel-dots-position-y == bottom) and
  ($carousel-details-style == below)
{
  .carouselImageMultiple .carouselSlideDetail {
    padding-bottom: calc(
      #{$carousel-details-padding} + #{$carousel-dots-padding} + 40px
    );
  }
}

// If we have multiple slides, and we have enabled, split arrow controls...
// AND we have vertically central carousel details...
// then we need to make sure that the contents never collide with the controls
@if ($carousel-controls-enabled == true) and
  ($carousel-controls-position-x == split)
{
  @if $carousel-contents-max-width != $carousel-max-width {
    @media (min-width: $carousel-breakpoint + 1px) and (max-width: calc(#{$carousel-contents-max-width} + #{$carousel-controls-button-size} + #{$carousel-controls-margin-x} + #{$gap-width})) {
      .carouselImageMultiple .carouselDetailWrapper {
        padding-left: calc(
          #{$carousel-controls-button-size} + #{$carousel-controls-margin-x} + #{$gap-width}
        );
        padding-right: calc(
          #{$carousel-controls-button-size} + #{$carousel-controls-margin-x} + #{$gap-width}
        );
      }
    }
  } @else if
    ($carousel-details-style == overlay) and
    ($carousel-details-min-height == 100%)
  {
    @media (min-width: $carousel-breakpoint + 1px) {
      .carouselImageMultiple .carouselSlideDetail {
        padding-left: calc(
          #{$carousel-controls-button-size} + #{$carousel-controls-margin-x} + #{$gap-width}
        );
        padding-right: calc(
          #{$carousel-controls-button-size} + #{$carousel-controls-margin-x} + #{$gap-width}
        );
      }
    }
  } @else {
    @media (min-width: $carousel-breakpoint + 1px) {
      .carouselImageMultiple .carouselDetailWrapper {
        padding-left: calc(
          #{$carousel-controls-button-size} + #{$carousel-controls-margin-x} + #{$gap-width}
        );
        padding-right: calc(
          #{$carousel-controls-button-size} + #{$carousel-controls-margin-x} + #{$gap-width}
        );
      }
    }
  }
}

.carouselSlideHeading {
  text-transform: $carousel-heading-text-transform;
  font-size: $carousel-heading-font-size;

  // If we have no summary text, remove bottom margin
  @if $carousel-summary-enabled == false {
    margin-bottom: 0;
  }

  a {
    color: $carousel-heading-colour;
  }
}

.carouselSlideSummary {
  @if $carousel-summary-enabled == false {
    display: none;
  } @else {
    color: $carousel-summary-colour;
    font-size: $carousel-summary-font-size;

    a,
    a:link,
    a:visited {
      color: inherit;
      text-decoration: underline;
    }
  }
}

.carouselSlideReadMore {
  @if $carousel-read-more-enabled == false {
    display: none;
  } @else {
    display: table;
    margin-top: $spacer;
    background-color: $carousel-read-more-background-colour;
    color: $carousel-read-more-colour;
    font-size: $carousel-read-more-font-size;

    @if $carousel-details-text-align == center {
      margin-left: auto;
      margin-right: auto;
    }

    // Ghost button
    @if $carousel-read-more-background-colour == transparent {
      @include ghost-button(
        $border-colour: $carousel-read-more-border-colour,
        $text-colour: $carousel-read-more-colour
      );
    }
  }
}

// If we want to hide the summary text but retain the read more
@if ($carousel-summary-enabled == false) and
  ($carousel-read-more-enabled == true)
{
  .carouselSlideSummary {
    display: block;
    font-size: 0;
  }

  .carouselSlideReadMore {
    font-size: 1rem;
  }
}

// Overlay
@media (min-width: $carousel-breakpoint + 1px) {
  @if $carousel-details-style == overlay {
    .carouselDetailWrapper {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .carouselSlideDetail {
      max-width: $carousel-details-max-width;
      @if $carousel-details-min-height != auto {
        min-height: $carousel-details-min-height;
        display: flex;
        flex-direction: column;

        @if $carousel-details-position-y == center {
          justify-content: center;
        }

        @if $carousel-details-position-y == bottom {
          justify-content: flex-end;
        }
      }
    }
  }

  // Adjacent
  @if $carousel-details-style == adjacent {
    .carouselSlide {
      display: flex;

      @if $carousel-details-position-x == left {
        flex-direction: row-reverse;
      }
    }

    .carouselSlideTitle {
      flex-grow: 1;
    }

    .carouselDetailWrapper {
      width: $carousel-details-max-width;
      flex-shrink: 0;
      flex-grow: 0;
      background-color: $carousel-details-background-colour;
    }
  }
}

//
// Appeal carousels
//

// Hide quick giving panel and toatliser from carousel details
// There's never really room frankly
.carousel .totaliserGivingPanel,
.carousel .totaliserPanel {
  display: none;
}

.carouselSlideDetail.appeal {
  // Lose read more
  .carouselSlideReadMore,
  .readMore {
    display: none;
  }

  .button:not(.donate) {
    background-color: $carousel-read-more-background-colour;
    color: $carousel-read-more-colour;
    &:hover {
      @include button-hover-state($carousel-read-more-background-colour);
    }
  }

  @if $donate-colour == $carousel-details-background-colour {
    .button.donate {
      background-color: darken($donate-colour, 10%);
    }
  }
}

//
// Responsive
//

@media (max-width: $carousel-breakpoint) {
  // If we have carousel dots at the bottom,
  // then we need to leave extra padding so the carousel details
  // don't interfere with the actual dots
  @if ($carousel-buttons-enabled == true) and
    ($carousel-buttons-type == dots) and
    ($carousel-dots-position-y == bottom)
  {
    .carouselImageMultiple .carouselSlideDetail {
      padding-bottom: calc(1rem + #{$carousel-dots-padding} + 40px);
    }
  }

  .carouselDetailWrapper {
    max-width: 100%;
    padding: 0;
  }

  .carouselSlideDetail {
    margin: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-color: $carousel-mobile-details-background-colour;
    padding: 1rem;
  }

  .carouselSlideHeading a {
    color: $carousel-mobile-heading-colour;
  }

  .carouselSlideSummary {
    @if $carousel-summary-enabled == true {
      color: $carousel-mobile-summary-colour;
    }
  }

  @if ($carousel-read-more-enabled == true) and
    (
      $carousel-read-more-background-colour ==
        $carousel-mobile-details-background-colour
    )
  {
    .carouselSlideReadMore {
      background-color: darken($carousel-read-more-background-colour, 10%);
      &:hover {
        @include button-hover-state($carousel-read-more-background-colour, 15%);
      }
    }
  }
}

// Set a min-height on the banner image on mobile
@include max-width(sm) {
  @supports (object-fit: cover) {
    .carouselSlideTitle,
    .page-has-banner .carousel:not(.hasCaption) .carouselSlide {
      min-height: $banner-min-height;

      img {
        height: 100%;
        object-fit: cover;
        min-height: $banner-min-height;
      }
    }
  }
}
