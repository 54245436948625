// Posts with a banner and a video banner need a little play button
// to appear over the banner image, to indicate that clicking on
// the banner image will launch the video modal

.carouselSlideTitle.showsVideo {
  background-color: $brand-primary;

  img {
    transition: opacity $transition-duration $transition-curve;
  }

  // The button itself
  &:before {
    content: '\f04b';
    font-family: $icon-font-family;
    font-size: 40px;
    text-align: center;
    line-height: 80px;
    width: 140px;
    height: 80px;
    border-radius: 20px;
    background-color: rgba($btn-background-colour, 0.75);
    color: $btn-text-colour;
    position: absolute;
    transition: all $transition-duration-slow $transition-curve;
    z-index: 1;

    // Position it center center
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  &:hover {
    text-decoration: none;

    img {
      opacity: 0.8;
    }

    &:before {
      transform: translateY(-50%) scale(1.2);
      background-color: $btn-text-colour;
      color: $btn-background-colour;
    }
  }
}

@media (min-width: $carousel-breakpoint + 1px) {
  .carousel.hasCaption .carouselSlideTitle.showsVideo {
    &:before {
      top: $carousel-video-btn-position-y;
      left: $carousel-video-btn-position-x;
      right: initial;
      margin: 0;
      transform: translateY(-50%) translateX(-50%);
    }

    &:hover:before {
      transform: translateY(-50%) translateX(-50%) scale(1.2);
    }
  }
}

//
// Responsive
//

@media (max-width: $carousel-breakpoint) {
  .carouselSlideTitle.showsVideo {
    position: relative;
  }
}

@include max-width(md) {
  .carouselSlideTitle.showsVideo:before {
    font-size: 30px;
    height: 60px;
    line-height: 60px;
    border-radius: 15px;
    width: 105px;
  }
}

@include max-width(sm) {
  .carouselSlideTitle.showsVideo:before {
    font-size: 23px;
    height: 46px;
    line-height: 46px;
    border-radius: 11px;
    width: 80px;
  }
}
