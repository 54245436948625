// Appeal/fundraiser posts have an appeal actions at the top of the page
// These are the donate and fundraise buttons that appear above the content

.post .appealActionsWrapper {
  @include context-container (
    $context-container-padding: $button-actions-padding,
    $context-container-background-colour: $button-actions-background-colour
  );
  @include parentless-container-wrapper($post-content-max-width);
  z-index: 1;

  @if $header-sticky-enabled == false {
    position: sticky;
    top: 0;
  }

  @include min-width(sm) {
    font-size: $font-size-large;
  }

  .contentBlock {
    margin: 0;
  }

  .appealActions {
    display: flex;

    @if ($button-actions-align-buttons == center) {
      justify-content: center;
    }

    @if ($button-actions-align-buttons == right) {
      justify-content: flex-end;
    }

    .button {
      margin: 4px;
    }
  }
}


@include max-width(sm) {
  .post .appealActionsWrapper {
    padding: 10px;

    .appealActions .button {
      flex: 1;
    }
  }
}

@media (max-width: 300px) {
  .post .appealActionsWrapper .appealActions {
    flex-wrap: wrap;

    .appealActions .button {
      flex: auto;
      width: 100%;
    }
  }
}