// Lots of the styles for this live in _social-icons.scss

@if $share-enabled == false {
  .shareWrapper {
    display: none;
  }
} @else {
  .shareWrapper {
    text-align: $share-text-align;
    margin-bottom: $gap-width;

    // 'Share' label
    > strong {
      display: block;
      font-family: $share-label-font-family;
      margin-bottom: $spacer / 2;
      font-size: $share-label-font-size;
      color: $share-label-font-colour;
    }

    .shareList {
      @if $share-text-align == center {
        justify-content: center;

        li {
          margin: 0 ($social-icons-gap / 2) !important;
        }
      }

      @if $share-text-align == right {
        justify-content: flex-end;

        li {
          margin-right: 0 !important;
          margin-left: $social-icons-gap;
        }
      }
    }

  }
}
