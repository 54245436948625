// Restrict max width of "listing intro" text...
.formDonatePage .listingIntro {
  width: 100%;
  max-width: $post-content-max-width;
  margin-left: auto;
  margin-right: auto;
  padding-left: $site-bleed;
  padding-right: $site-bleed;
}

.post .donationFormItems .contentBlockWrapper {
  margin-top: 0;

  > section {
    @include form-group
  }

  // e.g. 1. Your Donation
  legend {
    @extend h2;
  }
}


// Page banner image
@if $donation-form-banner-enabled == false {
  .formDonatePage .carousel {
    display: none;
  }
}

.selectDonationAmount {
  margin-bottom: $gap-width / 2;
}

// Donation form when stripe repeat payments has slightly different markup in the header of the section
// See: https://github.com/RaisingITDev/issues-inbox/issues/1264

.donationForm .yourDonationHeader {
  display: flex;
  align-items: center;
  margin-bottom: .5em;

  legend {
    margin: 0 20px 0 0 !important;
  }

  .donationFrequency {
    border: 0;
    padding: 0;
    flex-shrink: 1;
  }

  a {
    padding: 15px;
    background-color: $btn-background-colour;
    color: $btn-text-colour;
    transition: all $btn-transition-duration $transition-curve;
    font-weight: $btn-font-weight;

    &:hover {
      text-decoration: none;
      color: $btn-text-colour;
      cursor: pointer;
      @include button-hover-state($btn-background-colour);
    }

    &:after {
      content: '\f054';
      font-family: $icon-font-family;
      margin-left: .5em;
    }
  }

  @media (max-width: map-get($breakpoints, sm)) {
    .donationFrequency {
      margin: 0;
      font-size: 1em;
      width: auto;
      white-space: nowrap;
    }

    legend {
      margin: 0 10px 0 0 !important;
      font-size: $font-size-h4 !important;
    }

    a {
      width: 100%;
      padding: 10px;
    }
  }

  @media (max-width: 360px) {
    .donationFrequency {
      font-size: $font-size-small;
    }

    legend {
      font-size: $font-size-h5 !important;
    }
  }
}

// "Please select your donation amount"
.selectDonationAmount > span:first-child {
  display: block;
  margin-bottom: $gap-width / 2;
}

// Global donation amounts styles, regardless of donation form layout
.formQuestion.donationAmount:not(.ccAmount) {
  border: 1px solid $border-colour;
  border-radius: $border-radius;
  overflow: hidden;
  background-color: transparent;
  transition: all $btn-transition-duration $transition-curve;

  &:hover {
    cursor: pointer;
  }

  &.donationSelected {
    background-color: $donate-colour;
    border-color: $donate-colour;
    color: text-contrast($donate-colour);

    .donationAmountFigure {
      color: inherit;
    }
  }

  .checkboxRadio {
    margin: 0;
    cursor: inherit;
  }

  .donationAmountFigure {
    font-size: $donation-amount-figure-font-size;
    color: $donation-amount-figure-colour;
  }
}

@if $donation-amount-thumbnail-enabled == false {
  .donationAmountThumbnail {
    display: none;
  }
} @else {
  .donationAmountThumbnail {
    @supports (object-fit: cover) {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}


// Rows layout
@if $donation-form-layout == rows {
  .yourDonation .donationAmountsWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .yourDonation .donationAmountsWrapper .formQuestion.donationAmount {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 0;
    margin-bottom: $spacer;

    // Send the radio icon to the right of the row
    input[type="radio"] {
      order: 2;
      flex-grow: 0;
      flex-shrink: 0;
      margin-right: 15px; // Clear edge
    }

    .checkboxRadio {
      flex-grow: 1;
      display: grid;
      grid-template-columns: 15px 200px 1fr 15px;
      grid-template-rows: 15px auto auto 15px;
    }

    .donationAmountThumbnail {
      grid-column: 1 / 3;
      grid-row: 1 / 5;
      width: 100%;

      @supports (object-fit: cover) {
        height: 100%; // This causes a major bug in Safari
        max-height: 200px; // Trying to off set the Safari issues
      }
    }

    .donationAmountFigure {
      grid-column: span 3; // Make sure it grows when there's no image
      grid-row: 2 / 3;
      padding-left: 15px;
    }

    .donationAmountDescription {
      grid-column: span 3; // Make sure it grows when there's no image
      grid-row: 3 / 4;
      padding-left: 15px;
    }

    // If there IS an image, only span 2 columns.
    // Because the figure is BEFORE the image in the DOM, there's no way
    // of us checking for the same precedence
    .donationAmountThumbnail ~ .donationAmountDescription {
      grid-column: span 2;
    }
  }

  //
  // Responsive
  //
  @include max-width(sm) {
    .yourDonation .donationAmountsWrapper .formQuestion.donationAmount {
      flex-direction: column;

      .checkboxRadio {
        display: flex;
        flex-direction: column;
        width: 100%;
        text-align: center;
      }

      .donationAmountThumbnail {
        order: -1;
        width: 100%;
      }

      .donationAmountFigure,
      .donationAmountDescription {
        padding: 0;
        margin: 5px 0;
      }

      input[type="radio"] {
        margin: 10px 0;
      }
    }
  }
}

// Columns layout
@if $donation-form-layout == columns {
  // Reduce the lateral padding on the section so we can use the
  // flexbox gap margin approach for self healing amounts
  @include min-width(sm) {
    .donationFormItems .contentBlockWrapper > section:first-child {
      padding-left: $gap-width - 8px;
      padding-right: $gap-width - 8px;
    }


    .donationForm {
      .selectDonationAmount > *:not(.donationAmountsWrapper),
      .donationFrequency,
      .donationPeriod,
      .donationDate,
      .donationGiftAid,
      .donationComment {
        margin-left: auto !important;
        margin-right: auto !important;
        max-width: calc(100% - 16px);
      }
    }
  }

  .donationAmount.donationAmountOther {
    margin-top: 8px;
  }

  .donationAmountsWrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .yourDonation .donationAmountsWrapper .formQuestion.donationAmount {
    margin: 8px; // Flexbox gap
    flex: 1;
    flex-grow: 1;
    min-width: $donation-amount-min-width;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-bottom: 20px; // Bump it a bit

    @include max-width(sm) {
      margin: 8px 0;
    }

    // Get rid of the radio
    input {
      display: none;
    }

    .checkboxRadio {
      display: flex;
      flex-direction: column;
    }

    .donationAmountThumbnail {
      order: -1; // Hoist the thumbnail to the top
      width: 100%; // Specifically fixes an IE bug with image stretching
    }

    .donationAmountFigure {
      margin: 15px 0 0;
    }

    .donationAmountFigure,
    .donationAmountDescription {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}


// Donation amount other
.formQuestion.donationAmount.donationAmountOther {
  width: 100%;
  flex-direction: row;
  padding-bottom: 0;
  background-color: $grey-lightest;

  label {
    margin: 15px;
  }

  // The amount input
  #donationAmountOther {
    flex-grow: 1;
    border-radius: 0;
    border: 0;
    font-size: $donation-amount-figure-font-size;
  }
}


// Regular payment
.formQuestion.donationFrequency {
  display: flex;
  flex-direction: row-reverse; // Send the checkbox first
  align-items: center;
  width: 100%;
  padding: 15px;
  border-radius: $border-radius;
  border: 1px solid $border-colour;
  font-size: $font-size-large;
  margin-top: 16px;

  label {
    margin: 0 0 0 .5rem;
    flex-grow: 1;
  }

  &.frequencySelected {
    background-color: $donate-colour;
    border: 0;
    color: text-contrast($donate-colour);
    border-bottom-left-radius: 0; // We want it to jut up against the conditional questions
    border-bottom-right-radius: 0;
  }
}

// Conditional fields that are revealed if regular payment
.formQuestion.donationPeriod,
.formQuestion.donationDate {
  background-color: $donate-colour;
  color: text-contrast($donate-colour);
  padding: 0 15px 15px 15px;
}

.formQuestion.donationDate {
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
}


// Validation error message
.donationAmountInvalid {
  display: block; // .hidden class will still hide it
  margin-bottom: 15px;
}

// Donation comment
.donationComment {
  display: flex;
  flex-direction: column;
}


// Field groups
.formQuestion.donationPeriod,
.formQuestion.donationDate,
.paymentForm .formQuestion,
.registerForm .formQuestion:not(.labelCheckBoxPair) {
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  label {
    margin: 0 .5rem 0 0;
    width: $field-group-label-width;
    flex-grow: 0;
  }

  input, select, textarea {
    flex-grow: 1;
  }

  .validateRequired,
  .validateError {
    order: 3;
    width: 100%;
    @include min-width(md) {
      margin-left: calc(#{$field-group-label-width} + .5rem);
    }
  }
}

// "Display your name with your donation on our site"
.formQuestion.displayName {
  flex-direction: row;
  align-items: center;
  padding-bottom: 15px;

  @include min-width(md) {
    margin-left: calc(#{$field-group-label-width} + .5rem);
  }

  label {
    margin: 0 0 0 .5rem;
  }
}

// "Keeping in touch" intro block
// Add some top margin to seperate it from the core about you fields
.formDonatePage .formQuestion.contactYou {
  margin-top: $gap-width;
}

.registerFields {
  h2 {
    font-size: $font-size-h4;
  }

  // Add top margin to "Your privacy" heading to seperate it from
  // bottom of keeping in touch checkboxes
  #privacySectionTitle {
    margin-top: $gap-width;
  }

  // Sort out some padding with empty paragraphs here
  .privacyNotice + p,
  .privacyNotice + p + p {
    margin-bottom: 0;
  }
}

// Related appeal
// TODO: I'm hiding this for now, but could be a nice feature
.donationForm {
  .supportedAppeal,
  .appealTotaliser {
    display: none;
  }
}


//
// Responsive
//

@include max-width(md) {
  .formQuestion.donationPeriod,
  .formQuestion.donationDate,
  .paymentForm .formQuestion,
  .registerForm .formQuestion:not(.labelCheckBoxPair) {
    flex-direction: column;

    label {
      width: 100%;
      margin: 0 0 .5em;
    }

    input, select, textarea {
      width: 100%;
    }
  }

  .formQuestion.cardStartDate,
  .formQuestion.cardEndDate {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

@include max-width(sm) {
  .post .donationFormItems .contentBlockWrapper > section {
    &:not(:last-of-type) {
      margin: 0;
    }
  }
}