// We're injecting a button on top of the carousel banner that tells users
// that they can scroll down to see more content... Because clients don't
// believe users will scroll!

@if $carousel-scroll-btn-enabled == false {
  .carouselScrollBtn {
    display: none;
  }
} @else {
  .carouselScrollBtn {
    display: none; // Opt in once we know we have a banner image
    position: absolute;
    left: 0;
    right: 0;
    bottom: $carousel-scroll-btn-margin-y;
    margin: 0 auto;
    width: $carousel-scroll-btn-size;
    height: $carousel-scroll-btn-size;
    background-color: $carousel-scroll-btn-background-colour;
    border-radius: $carousel-scroll-btn-border-radius;
    box-shadow: $carousel-scroll-btn-box-shadow;
    overflow: hidden;
    z-index: 1;

    &:before {
      content: '\f078';
      display: block;
      font-family: $icon-font-family;
      line-height: $carousel-scroll-btn-size;
      width: $carousel-scroll-btn-size;
      height: $carousel-scroll-btn-size;
      text-align: center;
      font-size: $carousel-scroll-btn-icon-font-size;
      color: $carousel-scroll-btn-icon-colour;
    }

    &:hover {
      text-decoration: none;
      cursor: pointer;
    }

    @if $carousel-scroll-btn-animation-prefab == 1 {
      @keyframes carouselScrollBtnAnimation1 {
        from,
        2%,
        5.3%,
        8%,
        to {
          animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
          transform: translate3d(0, 0, 0);
        }

        4%,
        4.3% {
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          transform: translate3d(0, -10px, 0);
        }

        7% {
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          transform: translate3d(0, -5px, 0);
        }

        9% {
          transform: translate3d(0, -1px, 0);
        }
      }

      animation-name: carouselScrollBtnAnimation1;
      animation-duration: 8s;
      animation-iteration-count: infinite;
      animation-delay: 2s;
      transform-origin: center bottom;
    }

    @if $carousel-scroll-btn-animation-prefab == 2 {
      @keyframes carouselScrollBtnAnimation2 {
        from,
        to {
          transform: scale(1);
        }

        50% {
          transform: scale(1.3);
        }
      }

      animation: carouselScrollBtnAnimation2 5s infinite;
    }

    @if $carousel-scroll-btn-animation-prefab == 3 {
      @keyframes carouselScrollBtnAnimation3 {
        from,
        to {
          transform: translateY(0);
        }

        50% {
          transform: translateY(-25%);
        }
      }

      animation: carouselScrollBtnAnimation3 5s infinite;
    }
  }

  @media (min-width: $carousel-breakpoint + 1px) {
    .page-has-banner .carouselScrollBtn {
      display: block;
    }
  }

}
