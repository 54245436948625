// The progress meter appears at the top of multi-step checkout processes
// Such as the donation flow, after the 3DS2 payment work in Q32019

$progress-meter--grey : #707070;
$progress-meter--success: #008500;

.progress-meter {
  display: flex;
  justify-content: center;
  background-color: $grey-lightest;
  border: 1px solid $border-colour;
  border-radius: $context-container-border-radius;
  padding: 20px 30px;
  width: 100%;
  text-align: center;
  @include parentless-container-wrapper($post-content-max-width);
  margin-bottom: $gap-width;
}

.progress-meter__step {
  width: 75px;
  color: $progress-meter--grey;
  margin: 0 15px;
  position: relative;

  span {
    display: block;
    line-height: 1.1;
  }
}

/* Circle */
.progress-meter__step:before {
  content: '';
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  margin: 0 auto 5px;
  font-size: 14px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center;
}

.progress-meter__step--completed {
  color: $success-colour--on-white;
  &:before {
    background-color: $progress-meter--success;
    background-image: url('/baseui/v2_4/Images/check.svg');
  }
}

.progress-meter__step--current {
  font-weight: 700;
  color: $progress-meter--success;
}

.progress-meter__step--current:before {
  border: 2px solid $progress-meter--success;
  background-image: url('/baseui/v2_4/Images/chevron-down.svg');
  background-position: center top 10px; /* Slightly off vertical center */
}

.progress-meter__step--todo:before {
  border: 2px solid $progress-meter--grey;
  background-image: url('/baseui/v2_4/Images/chevron-right.svg');
  background-position: left 10px center; /* Slightly off lateral center */
}

/* Connector */
.progress-meter__step:after {
  content: '';
  width: 62px;
  height: 2px;
  border-radius: 2px;
  background-color: #dfdfdf;
  display: block;
  position: absolute;
  top: 14px;
  left: 58px; /* Hard code this into position because we know the width of each step */
}

.progress-meter__step:last-child:after {
  display: none;
}

@media (max-width: 576px) {
  .progress-meter {
    flex-direction: column;
    align-items: center;
    text-align: left;
  }

  .progress-meter__step {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    margin: 0 0 10px;
  }

  .progress-meter__step span {
    flex-grow: 1;
  }

  .progress-meter__step:before {
    flex-grow: 0;
    margin: 0 12px 0 0;
  }

  .progress-meter__step:after {
    display: none;
  }
}
