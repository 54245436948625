// Australian clients handle repeat payments slightly differently
// Users are directed from the main donate form to this secondary form
// Technically this CSS is only required for Aus clients but whilst we have no context for client internationalisation within Fresco I can't escape this

.donation-amounts {
  flex-direction: column;
}

// Converting this margin to a padding because it is causing collapsible margin issues when sticky header
.repeat-payment {
  margin-top: 0;
  padding-top: 40px;
}

.repeat-payment > div {
  display: flex;
  flex-direction: column;
}

.repeat-payment button.ladda-button {
  display: block;
  font-size: $font-size-large;
  margin: 0 auto;
}

.repeat-payment .dataProtection {
  margin-top: $gap-width;
}