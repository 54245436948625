// We use a third party image uploading service called Uploadifive
// It ships with some .css that we're still importing even in the nobaseframework universe
// So I'm overwriting some vendor styles here

.uploadifive-button {
  display: inline-block !important;
  vertical-align: middle !important;
  background-color: $btn-background-colour !important;
  color: $btn-text-colour !important;
  border: none !important;
  outline: 0 !important;
  border-radius: $btn-border-radius !important;
  padding: $btn-padding-y $btn-padding-x !important;
  height: auto !important;
  width: auto !important;
  line-height: $btn-line-height !important;
  font-size: 1rem !important;
  font-weight: $btn-font-weight !important;
}