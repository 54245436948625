@if $shop-enabled == true {
  .productPostPage {
    margin-top: 3rem;
  }

  .productPostPage .post {
    .contentBlockWrapper {
      max-width: $product-post-content-max-width;
    }

    .contentBlock {
      display: flex;
      flex-direction: column;
    }

    .postAside {
      display: none; // TODO: Some weird stuff gets generated in here like related products
    }
  }

  // Additional product images
  .productBannerList {
    @include list-unstyled;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: auto;
    grid-column-gap: 5px;

    li {
      opacity: .65;
      transition: opacity $transition-duration $transition-curve;

      &:hover {
        opacity: 1;
      }
    }
  }

  // Out of stock
  .productOut {
    color: $danger-colour--on-background;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    font-size: $font-size-small;
    background-color: $danger-colour--background;
    border: 1px solid darken($danger-colour--background, 10%);
    padding: .25em .5em;
    margin-left: .5em;
    border-radius: 4px;
    display: inline-block;
  }

  .continueShoppingWrapper {
    @include parentless-container-wrapper($product-post-content-max-width);
    margin-top: $breadcrumb-margin-top;
    margin-bottom: $breadcrumb-margin-bottom;

    .button {
      @include button-reset;
      @include text-link;
      display: inline-flex;
      align-items: baseline;

      &:before {
        content: '\f053';
        font-family: $icon-font-family;
        display: inline-block;
        margin-right: 0.5em;
        font-size: 0.75em;
      }
    }
  }

  .productContentWrapper {
    margin-top: $spacer * 2;
    display: flex;
    flex-wrap: wrap;

    .productBannerWrapper {
      width: 30%;
      min-width: 200px;
      margin-right: $spacer * 3;
      flex-grow: 0;
      flex-shrink: 0;

      span {
        display: block;
        width: 100%;
        border-radius: $border-radius;
        overflow: hidden;
      }

      img {
        display: block;
        width: 100%;
      }
    }

    .productText {
      flex: 1;
      max-width: 100%; // Purchase options overflowed narrow viewports without this.
    }

    .productReviews {
      width: 100%;
    }
  }

  // Quantity, add to basket and checkout buttons
  .productPurchase {
    @include context-container;
    margin: ($spacer * 2) 0;
  }

  .productQuantity {
    display: flex;
    flex-direction: column;
    margin-bottom: $spacer;
  }

  // We get another button at the bottom
  // Decision taken 16/09/20 to hide this button - Jonny, Louise & Jenni
  // 1. There is no feedback that a product has ACTUALLY been added to your basket
  // 2. It is hidden in baseframework.css - so pre-Fresco/SC clients don't have it
  .productPostPage .productFooter .addToBasket {
    display: none;
  }

  //
  // Responsive
  //

  @include max-width(md) {
    .productContentWrapper {
      margin-top: 0;
      flex-direction: column;

      .productBannerWrapper {
        width: 100%;
        min-width: auto;
        margin: 0 0 $spacer;
      }
    }
  }

  @include max-width(sm) {
    .productActions {
      display: flex;
      flex-direction: column;

      .button {
        margin: 0 0 5px;
      }
    }
  }
}
