// A list of top fundraisers per appeal
// which appears in an appeal's post footer
@if $fundraising-enabled == false or $top-fundraisers-enabled == false {
  .topFundraisersWrapper {
    display: none;
  }
} @else {
  .topFundraisersWrapper {
    @include context-container;

    > strong {
      @extend h5;
      display: block;
      margin-bottom: 15px;
    }

    ol {
      @include list-unstyled;
      display: flex;
      flex-direction: column;
      font-size: $font-size-small;
    }

    .listedTopFundraiser {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .profilePic {
      @if $top-fundraisers-profile-pics-enabled == false {
        display: none;
      } @else {
        max-width: 40px;
        margin-right: 15px;
      }
    }

    .fundraiserDetails {
      flex-grow: 1;
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;

      h3, p {
        font-size: 1em;
        margin: 0;
      }

      h3 {
        margin-right: .25em;
      }

      // The amount they've raised
      p span {
        color: $donate-colour;
      }
    }
  }
}