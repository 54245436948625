// Quick giving panel gets quite a lot of its styles from the _donation-form.scss
// There might also be some specific styles related to the homepage quick giving panel

.quickGivingPanel {
  @include context-container(
    $context-container-padding: $quick-giving-padding,
    $context-container-border-radius: $quick-giving-border-radius,
    $context-container-background-colour: $quick-giving-background-colour
  );

  // Add gradient if required
  @if $quick-giving-background-gradient != none {
    background: $quick-giving-background-gradient;
  }

  width: 100%;
  text-align: center;

  // Hide the "Please select a donation amount" label here
  // Let's get straight to the point
  .selectDonationAmount > span:first-child {
    display: none;
  }

  .formQuestion.donationAmount {
    background-color: $quick-giving-donation-amount-background-colour;
    border: 0;
    padding: 0;
    display: inline-flex;
    vertical-align: middle;
    margin: 4px;
    color: $quick-giving-donation-amount-figure-colour;

    &:first-of-type {
      margin-left: 0;
    }

    // Hide radio button,
    input[type='radio'] {
      display: none;
    }

    .donationAmountFigure {
      display: block;
      color: inherit;
      padding: $quick-giving-donation-amount-padding;
      font-size: $quick-giving-donation-amount-figure-font-size;
    }
  }

  // Ghost donation amount button
  @if $quick-giving-donation-amount-background-colour == transparent {
    .formQuestion.donationAmount {
      border: $quick-giving-donation-amount-border-width solid $quick-giving-donation-amount-border-colour;

      &:hover {
        background-color: $quick-giving-donation-amount-border-colour;

        .donationAmountFigure {
          color: text-contrast($quick-giving-donation-amount-border-colour);
        }
      }
    }
  }

  .formQuestion.donationAmount.donationSelected {
    background-color: $quick-giving-donation-amount-selected-background-colour;
    border-color: $quick-giving-donation-amount-selected-background-colour; // Primarily in case of ghost donation amount
    color: $quick-giving-donation-amount-selected-figure-colour;

    .donationAmountFigure {
      color: inherit;
    }
  }

  // Other
  .formQuestion.donationAmount.donationAmountOther {
    width: auto;
    display: inline-flex;
    position: relative;

    label {
      margin: 0;
    }

    // Input
    #donationAmountOther {
      flex-grow: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0; // Only reveal if selected
      width: 100%;
    }

    &.donationSelected #donationAmountOther {
      opacity: 1;
    }
  }

  // "Set up a regular payment"
  // Hide this, it's hard to style nicely
  // And we want to get people to the form
  .selectDonationAmount > span:not(:first-child),
  .selectDonationAmount > a[href*="repeat-payment"],
  .regularPayment {
    display: none;
  }

  // Donate button
  .button {
    margin: $gap-width auto 0;
    display: table; // Best way to make this width auto
  }
}

// Simple
@if $quick-giving-type == simple {
  .quickGivingPanel {
    // Hide donation amount image and description
    img,
    .donationAmountDescription {
      display: none;
    }

    .donationAmountsWrapper {
      display: inline-flex;
      vertical-align: middle;
    }
  }

  // I introduced this, but I'm not sure why and it seems to be adding a bug
  // So taking it out for now
  // .postContent .quickGivingPanel .donationAmount.donationAmountOther {
  //   margin-left: 0;
  // }

  // Responsive simple
  @include max-width(md) {
    .quickGivingPanel {
      .formQuestion.donationAmount,
      .formQuestion.donationAmount.donationAmountOther {
        display: block;
        width: 100%;
        margin: 5px 0;
      }

      .donationAmountsWrapper {
        width: 100%;
      }

      .formQuestion.donationAmount.donationAmountOther.donationSelected
        #donationAmountOther {
        width: 100%;
      }
    }
  }
}

// Column
@if $quick-giving-type == columns {
  .contentBlock .quickGivingPanel,
  .subsiteBody .quickGivingPanel {
    .checkboxRadio {
      display: flex;
      flex-direction: column;
    }

    img {
      width: 100%;
      order: -1;
    }

    .donationAmountFigure {
      margin: 0;
    }

    .donationAmountDescription {
      padding: $quick-giving-donation-amount-padding;
      padding-top: 0;
    }

    .donationAmountsWrapper .donationAmount {
      padding-bottom: 10px;
      font-size: $font-size-small;
      width: calc((100% - 24px) / 3);
      flex-grow: 1;
      min-width: 180px;

      &:first-of-type {
        margin-left: 4px;
      }
    }

    .donationAmount.donationAmountOther {
      width: calc(100% - 8px);
      margin: $spacer 4px;

      #donationAmountOther {
        position: relative;
        opacity: 1;
        padding: 0 0.5em;
        font-size: 1em;
        flex-grow: 1;
      }
    }
  }
}

// Rows
@if $quick-giving-type == rows {
  .contentBlock .quickGivingPanel,
  .subsiteBody .quickGivingPanel {
    .donationAmount {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
      text-align: left;
    }

    .donationAmount:not(.donationAmountOther) .donationAmountFigure {
      grid-row: 2 / 3;
      padding: 0 0 0 15px;
    }

    .checkboxRadio {
      display: grid;
      grid-template-columns: 10px 150px 1fr 10px;
      grid-template-rows: 10px auto auto 10px;
    }

    img {
      grid-column: 1 / 3;
      grid-row: 1 / 5;

      @supports (object-fit: cover) {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }

    .donationAmountDescription {
      grid-row: 3 / 4;
      padding-left: 15px;
    }

    .donationAmount.donationAmountOther {
      width: 100%;

      #donationAmountOther {
        position: relative;
        opacity: 1;
        padding: 0 0.5em;
        font-size: 1em;
        flex-grow: 1;
      }
    }
  }

  // Responsive rows
  @include max-width(sm) {
    .contentBlock .quickGivingPanel,
    .subsiteBody .quickGivingPanel {
      .donationAmount:not(.donationAmountOther) {
        text-align: center;
        .checkboxRadio {
          display: flex;
          flex-direction: column;
          padding-bottom: 10px;

          img {
            order: -1;
          }
        }

        .donationAmountFigure {
          padding: 10px 10px 0;
        }

        .donationAmountDescription {
          padding: 0 10px;
        }
      }
    }
  }
}
