@if $home-quick-giving-enabled == true {
  .homefeaturecategory-homeboxquickgiving {
    width: 100%;
    max-width: $home-quick-giving-max-width;
    margin-top: $home-quick-giving-margin-top;
    margin-bottom: $home-quick-giving-margin-bottom;
    margin-left: auto;
    margin-right: auto;
    padding-top: $home-quick-giving-padding-y;
    padding-bottom: $home-quick-giving-padding-y;
    position: relative;

    // Solid colour
    @if $home-quick-giving-background-gradient == none {
      background-color: $home-quick-giving-background-colour;
    }

    // Gradient
    @else {
      background: $home-quick-giving-background-gradient;
    }

    // Background image
    @if $home-quick-giving-background-image != null {
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        background-image: url($assets-path + $home-quick-giving-background-image);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        opacity: $home-quick-giving-background-image-opacity;
        z-index: 0;
      }
    }

    // Heading
    h1, h2, h3 {
      font-size: $home-quick-giving-heading-font-size;
      color: $home-quick-giving-heading-colour;
    }

    .homepage-quickgiving--copy {
      color: $home-quick-giving-colour;
    }

    .quickGivingPanel {
      background-color: transparent; // Remove any background colour
      background: initial; // Remove any gradient
      margin: 0;
      padding: 0;

      .formQuestion.donationAmount {
        background-color: $home-quick-giving-donation-amount-background-colour;

        .donationAmountFigure {
          color: $home-quick-giving-donation-amount-figure-colour;
        }

        // Ghost button
        @if $home-quick-giving-donation-amount-background-colour == transparent {
          border: $home-quick-giving-donation-amount-border-width solid $home-quick-giving-donation-amount-border-colour;

          &:hover {
            background-color: $home-quick-giving-donation-amount-border-colour;

            .donationAmountFigure {
              color: text-contrast($home-quick-giving-donation-amount-border-colour);
            }
          }
        }
      }

      // We never want this
      .donationAmountDescription {
        display: none;
      }

      .formQuestion.donationAmount.donationSelected {
        background-color: $home-quick-giving-donation-amount-selected-background-colour;
        border-color: $home-quick-giving-donation-amount-selected-background-colour; // Primarily in case of ghost donation amount button

        .donationAmountFigure {
          color: $home-quick-giving-donation-amount-selected-figure-colour;
        }
      }

      .donateBtn {
        background-color: $home-quick-giving-donate-btn-background-colour;
        color: $home-quick-giving-donate-btn-colour;
        font-size: $home-quick-giving-donate-btn-font-size;
        line-height: $line-height-base; // Reset this from button

        // Match height to sibling donation amounts
        $padding-quote: quote($quick-giving-donation-amount-padding);
        $padding-quote-space-index: str-index($padding-quote, ' ');
        @if $padding-quote-space-index != 0 {
          $padding-y: str-slice($padding-quote, 1, ($padding-quote-space-index - 1));
          padding: unquote($padding-y) $btn-padding-x;
        } @else {
          padding: $quick-giving-donation-amount-padding;
        }

        // Solid btn style
        @if $home-quick-giving-donate-btn-background-colour != transparent {
          &:hover {
            @include button-hover-state($home-quick-giving-donate-btn-background-colour);
          }
        }
        // Ghost btn style
        @else {
          @include ghost-button(
            $border-colour: $home-quick-giving-donate-btn-border-colour,
            $text-colour: $home-quick-giving-donate-btn-colour
          );
        }
      }
    }

    .selectDonationAmount {
      margin-bottom: 0;
    }
  }

  // Injected container with JS
  .homepage-quickgiving--container {
    display: flex;
    width: 100%;
    max-width: $home-quick-giving-content-max-width;
  }


  // simple
  @if $home-quick-giving-type == simple {
    .homefeaturecategory-homeboxquickgiving .quickGivingPanel {
      .donateBtn {
        display: inline-flex;
        margin: 4px;
        width: auto;
        vertical-align: middle;
      }

      img {
        display: none;
      }
    }

    .homepage-quickgiving--container {
      align-items: center;
      justify-content: center;
      padding-left: $site-bleed;
      padding-right: $site-bleed;
      margin: 0 auto;
    }

    .homepage-quickgiving--copy {
      margin-right: 30px;
      position: relative;

      // Remove margins from any children.
      // Solves the issue when there is just a heading here
      * {
        margin: 0;
      }

      // But if we have more than one, then likely we need to introduce a margin
      // gap between, say, the header and the supporting text
      > *:nth-child(2) {
        margin-top: $headings-margin-bottom;
      }
    }

    .formQuestion.donationAmount {
      position: relative;
    }

    @include max-width(lg) {
      .homepage-quickgiving--container {
        flex-direction: column;
        align-items: stretch;
        text-align: center;
      }

      .homepage-quickgiving--copy {
        margin: 0 0 30px 0;
      }
    }
  }

  @if $home-quick-giving-type == adjacent or $home-quick-giving-type == overlay {
    @if $warn == true {
      @warn "Don't forget to set the Javascript variable \"var transformHomepageDonationAmountImages = true\;\" to resize donation amount images!";
    }
  }

  // adjacent
  @if $home-quick-giving-type == adjacent {

    .homefeaturecategory-homeboxquickgiving {
      position: relative;
      display: flex;

      @if $home-quick-giving-type-adjacent-image-side != right {
        justify-content: flex-end;
      }

      .homepage-quickgiving--container {
        z-index: 1;
      }

      .quickGivingPanel {
        text-align: left;

        .donateBtn {
          margin-left: 0;
        }

        .formQuestion.donationAmount img {
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          width: 50%;
          height: 100%;
          object-fit: cover;
          opacity: 0;
          transition: opacity $transition-duration $transition-curve;
          pointer-events: none;

          @if $home-quick-giving-type-adjacent-image-side == right {
            right: 0;
          } @else {
            left: 0;
          }
        }

        .formQuestion.donationAmount.donationSelected img,
        .formQuestion.donationAmount:first-of-type img {
          opacity: 1;
        }
      }
    }

    .homepage-quickgiving--container {
      max-width: 50%;
      flex-direction: column;
      padding: 0 6%;
    }

    .homepage-quickgiving--copy {
      margin-bottom: 20px;
    }

    @media (max-width: 1024px) {
      .homefeaturecategory-homeboxquickgiving {
        padding: 0;

        .quickGivingPanel {
          text-align: center;
          .formQuestion.donationAmount img {
            width: 100%;
            height: auto;
          }

          .donateBtn {
            margin-left: auto;
          }
        }
      }

      .homepage-quickgiving--container {
        max-width: 100%;
        padding: 30px $site-bleed;
        text-align: center;
      }
    }
  }

  // overlay
  @if $home-quick-giving-type == overlay {
    .homefeaturecategory-homeboxquickgiving {
      position: relative;
      display: flex;
      min-height: 35vw;
      z-index: 0;

      @if $home-quick-giving-type-overlay-items-side == right {
        justify-content: flex-end;
      }

      // Gradient
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: $home-quick-giving-type-overlay-gradient;
        pointer-events: none;
        z-index: 1;
      }

      .homepage-quickgiving--copy {
        margin-bottom: 20px;
        z-index: 1;
      }

      .quickGivingPanel {
        text-align: left;

        .donateBtn {
          margin-left: 0;
          position: relative;
          z-index: 1;
        }

        .formQuestion.donationAmount {

          img {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: 0;
            transition: opacity $transition-duration $transition-curve;
            pointer-events: none;
            z-index: 0;
          }

          .donationAmountFigure {
            position: relative;
            background-color: $home-quick-giving-donation-amount-background-colour;
            z-index: 1;
          }

          &.donationSelected {
            .donationAmountFigure {
              background-color: $home-quick-giving-donation-amount-selected-background-colour;
            }

            img {
              opacity: 1;
            }
          }

          &:first-of-type img {
            opacity: 1;
          }
        }
      }

      #donationAmountOther {
        z-index: 1;
      }
    }

    .homepage-quickgiving--container {
      max-width: 50%;
      flex-direction: column;
      justify-content: center;
      padding: 0 6%;

      @if $home-quick-giving-type-overlay-items-side == right {
        display: inline-flex;
        width: auto;
      }
    }

    @media (max-width: 1024px) {
      .homefeaturecategory-homeboxquickgiving {
        padding: 0;
        min-height: auto;

        &:before {
          display: none;
        }

        .quickGivingPanel {
          text-align: center;
          .formQuestion.donationAmount img {
            width: 100%;
            height: auto;
          }

          .donateBtn {
            margin-left: auto;
          }
        }
      }

      .homepage-quickgiving--container {
        max-width: 100%;
        width: 100%;
        padding: 30px $site-bleed;
        text-align: center;
      }
    }
  }

  //
  // Responsive
  //
  @include max-width(md) {
    .homefeaturecategory-homeboxquickgiving .quickGivingPanel {
      .formQuestion.donationAmount,
      .formQuestion.donationAmount.donationAmountOther {
        display: block;
        width: 100%;
        margin: 5px 0;
      }

      .formQuestion label {
        width: 100%;
      }

      .formQuestion.donationAmount.donationAmountOther.donationSelected #donationAmountOther {
        width: 100%;
        text-align: center;
      }
    }
  }
}
