@if $shop-enabled == true {
  .productListOrder {
    @include list-unstyled;
    @include context-container($context-container-padding: 5px);
    margin: 0 ($card-gap-width / 2);
    font-size: $font-size-small;
    display: flex;
    flex-wrap: wrap;

    &:before {
      content: 'Sort by:';
      display: inline-flex;
      margin: 8px;
      opacity: 0.5;
    }

    li {
      margin: 8px;
      display: inline-flex;
    }
  }
}
